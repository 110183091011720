.workspace {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: left 0.3s ease-out;
    background-repeat: repeat;
    background: linear-gradient(90deg, #FBFBFB 36px, transparent 1%) center, linear-gradient(#FBFBFB 36px, transparent 1%) center, #ABB6BF;
    background-size: 38px 38px;

    box-shadow: 3px 3px 0 rgba(255, 255, 255, 0.3);
    overflow: hidden;

    display: flex;
    /* touch-action:auto; */
}

.workspace-tools-container {
    position: relative;
    flex: 1 1;
    pointer-events: none;
}

.force-dark-mode .workspace {
    background-repeat: repeat;
    background: linear-gradient(90deg, #21262c 36px, transparent 1%) center, linear-gradient(#21262c 36px, transparent 1%) center, #647682;
    background-size: 38px 38px;
}

.windowInner.notes-window-inner {
    height: calc(100% - 68px);
    margin-top: 36px;
}

@media (prefers-color-scheme: dark) {   
    .workspace {
        background-repeat: repeat;
        background: linear-gradient(90deg, #21262c 36px, transparent 1%) center, linear-gradient(#21262c 36px, transparent 1%) center, #647682;
        background-size: 38px 38px;
    }
    
    .force-light-mode .workspace {
        background-repeat: repeat;
        background: linear-gradient(90deg, #FBFBFB 36px, transparent 1%) center, linear-gradient(#FBFBFB 36px, transparent 1%) center, #ABB6BF;
        background-size: 38px 38px;
    }
}