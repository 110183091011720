.text-button {
    color:#647682;
    fill:#647682;
    font-weight: bold;
    text-transform: uppercase;
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
    box-shadow: none;
    display: initial;
    justify-content: initial;
}

.text-button:hover, .text-button:focus, .text-button:active {
    color:#465058;
    fill: #465058;
    background: none;
    box-shadow: none;
    transform: none;
}

/* Forced Dark Mode */
.force-dark-mode .text-button {
    color:#abb6bf;
    fill:#abb6bf;
}

.force-dark-mode .text-button:hover, .force-dark-mode .text-button:focus, .force-dark-mode .text-button:active {
    color:#eceff3;
    fill: #eceff3;
}

/* Dark Mode */
@media (prefers-color-scheme: dark) {
    .text-button {
        color:#abb6bf;
        fill:#abb6bf;
    }

    /* Forced Light theme in dark mode */
    .force-light-mode .text-button {
        color:#647682;
        fill:#647682;
    }

    .force-light-mode .text-button:hover, .force-light-mode .text-button:focus, .force-light-mode .text-button:active {
        color:#465058;
        fill: #465058;
        background: none;
        box-shadow: none;
    }
}

.text-button.blue {color: #7faabc; fill: #7faabc;}
.text-button.blue:hover, .text-button.blue:focus, .text-button.blue:active {color: #628ea1; fill: #628ea1;}

.text-button.green {color: #9bd095; fill: #9bd095;}

.text-button.green:hover, .text-button.green:focus, .text-button.green:active {color: #87C77F; fill: #87C77F;}

.text-button.red {color: #e17e7e; fill: #e17e7e;}
.text-button.red:hover, .text-button.red:focus, .text-button.red:active {color: #DF5353; fill: #e17e7e;}

.text-button.yellow {color: #FFDCAD; fill: #FFDCAD;}
.text-button.yellow:hover, .text-button.yellow:focus, .text-button.yellow:active {color: #d1a264; fill: #d1a264;}

.text-button .Icon {max-width: 16px;}