.card-button {
    justify-content: flex-start;
    border: 1px solid #DEE1E8;
    border-radius: 12px;
    background: white;
    color: #ABB6BF;
    padding: 2rem;
    box-shadow: 0px 0px 0 rgba(100, 118, 130, 0.15);
    transform: scale(1);

    transition: box-shadow 0.3s ease-out, transform 0.3s ease-out;
}

.card-button:hover {
    color: #ABB6BF;
    box-shadow: 3px 3px 0 rgba(100, 118, 130, 0.15);
    transform: scale(1.05);
}

.card-button:active {
    box-shadow: 0px 0px 0 rgba(100, 118, 130, 0.15);
    transform: scale(0.95);
}