.chest-dice-tab {
    width: 95%;
    margin: 0 auto;
}

.dice-icon {
    max-width: 32px;
    max-height: 32px;
    margin: 0.25rem;
}

.dice-display-container {
    border-radius: 8px;
}

.dice-tab-selected {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border-radius: 8px;
}