.newCampaign-window {
    padding: 2rem;
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: 100%;

    max-width: 512px;
    margin: 0 auto;
}

.tab .newCampaign-window {
    margin-top: 6rem;
    min-height: auto;
}

.newCampaign-titleInput {
    width: 100%;
}

.newCampaign-itemList-container {
    width: 100%;
    margin: 1rem auto;
    flex: 1 1;
}

.newCampaign-items { 
    height: 164px;
    margin-top: 2rem;
}

.newCampaign-tab-nav-container {
    width: 100%;
    max-width: 256px;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

/* View campaign */

.view-campaign {
    height: 100%;
}

.tab .view-campaign {
    margin-top: 6rem;
}

.view-campaign-user-list {
    height: 200px;
}

.waiting-for-invite-title {
    margin-bottom: 1rem;
}

.waiting-for-invite-loading {
    margin: 1rem;
}

.campaign-created-window {
    background-color: #86B4F4;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.campaign-created-window h1 {
    color: white;
}

.campaign-created-subtitle {
    text-align: center;
    color: #5B86C1;
    font-weight: bold;
}

.campaign-created-share-link {
    margin: 1rem auto 2rem auto;
    width: 80%;
    min-width: 128px;
    max-width: 300px;
}

.campaign-created-share-link .textInput {
    text-align: center;
}

.campaign-created-icon {
    width: 96px;
    height: 96px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: white;
    border-radius: 100%;
    border: 3px solid #647682;
}

.campaign-created-icon .Icon {
    width: 64px;
    height: 64px;
}

.waiting-for-invite-icon{
    display: flex;
    align-items: center;
    justify-content: center;
}

.waiting-for-invite-icon svg {
    stroke: #647682;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    padding: 1rem;
    margin: 1rem;
    stroke-width: 3px;
}

.waiting-for-invite-icon.success svg{
    background-color: #CEFFE8;
    border: 1px solid #647682;
}

.waiting-for-invite-icon.fail svg{
    background-color: #FC9494;
    border: 1px solid #647682;
}

.view-campaign-bottom-ui {
    width: calc(100% - 4rem);
}

.tab .view-campaign-bottom-ui {
    width: 50%;
}