.landing-features {
    background: #1F506E;
    padding: 1px;
}

.landing-feature {
    position: relative;
    max-width: 1024px;
    margin: 0 auto;
    height: 500px;
    padding: 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 6rem;
    padding: 0 2em;
}

.feature-img-container, .feature-text-container {
    position: relative;
    text-align: center;
    transition: opacity 1s, transform 1s;
}

.feature-img-container img {
    width: 100%;
    max-width: 512px;
    padding: 0.5rem;
    background-color: white;
    border: 3px solid #647682;
    border-radius: 12px;

    position: relative;
    z-index: 1;
}

.landing-feature[data-scroll="in"] .feature-img-container{
    opacity: 1;
    transform: translate(0, 0);
}

.landing-feature[data-scroll="out"] .feature-img-container{
    opacity: 0;
    transform: translate(0, 64px);
}

.landing-feature[data-scroll="in"] .clutter-item{
    opacity: 1;
    transform: translate(0, 0) !important;
}

.landing-feature[data-scroll="out"] .clutter-item{
    opacity: 0;
}

.feature-text-inner{
    max-width: 512px;
    color: white;
}

.feature-text-inner p {
    font-weight: bold;
    margin: 1rem 0;
}

.feature-text-inner a {
    margin: 0 auto;
}

.coming-soon-span {
    font-size: 1rem;
    font-weight: lighter;
    color: #58a6d6;
}

.landing-feature:nth-of-type(even){
    flex-direction: column;
}

.of-2:nth-of-type(1), .of-2:nth-of-type(2){
    background-color: #1F506E;
}

.of-2:nth-of-type(1) .feature-text-container, .of-2:nth-of-type(2) .feature-text-container{
    color: white;
}

.of-2:nth-of-type(1) h2, .of-2:nth-of-type(2) h2{
    color: white;
}

.clutter-item{
    position: absolute;
    width: 32px;
    height: 32px;
    transition: opacity 1s, transform 1s;
    transition-delay: 1s;
}

.clutter-item:nth-of-type(1) {
    display:none;
    top: 4%;
    left: 20%;
    transform: translate(-14px, -6px);
}

.clutter-item:nth-of-type(2) {
    display:none;
    top: 11%;
    left: 55%;
    transform: translate(20px, 1px);
}

.clutter-item:nth-of-type(3) {
    display:none;
    top: 78%;
    left: 9%;
    transform: translate(14px, -9px);
}

.clutter-item:nth-of-type(4) {
    display:none;
    top: 6%;
    left: 87%;
    transform: translate(5px, 0px);
}

.clutter-item:nth-of-type(5) {
    display:none;
    top: 85%;
    left: 45%;
    transform: translate(-2px, -12px);
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 375px) {
    
}

/* Small devices (portrait tablets and large phones, 650px and up) */
@media only screen and (min-width: 650px) {
    .landing-feature {
        flex-direction: row;
    }

    .landing-feature:nth-of-type(even){
        flex-direction: row-reverse;
    }

    .feature-text-container {
        margin-bottom: 1rem;
        margin-left: 1rem;
    }

    .landing-feature:nth-of-type(even) .feature-text-container{
        margin-right: 1rem;
    }

    .clutter-item:nth-of-type(1) {display: block;}
    .clutter-item:nth-of-type(2) {display: block;}
    .clutter-item:nth-of-type(3) {display: block;}
    .clutter-item:nth-of-type(4) {display: block;}
    .clutter-item:nth-of-type(5) {display: block;}
}

/* Medium devices (landscape tablets, 769px and up) */
@media only screen and (min-width: 769px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1440px) {
    
}