.settings {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.tab .settings {
    margin-top: 6rem;
}

.settings .tab-nav-tab {
    overflow-y: auto;
}

.settings-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-grow: 1;
    padding: 0 2rem;

    max-width: 512px;
    margin: 0 auto;
}

.settings label {
    font-size: 0.85rem;
}

.settings-container .Icon {
    width: 16px;
    height: 16px;
    fill: #767676;
    margin-right: 1rem;
    margin-bottom: 0.25rem;
}

.settings .tab-nav-header {
    width: 350px;
    margin: 0 auto;
}


.payment-complete-container {
    height: 100%;
}

.payment-complete-container h1 {
    color: white;
}

.payment-complete-container p {
    color: #5BA353;   
}

.payment-complete-icon {
    width: 128px;
    height: 128px;
    border: 3px solid #5BA353;
    background: white;
    border-radius: 100%;
    margin: 1rem auto;
}

.payment-complete-icon .Icon {
    width: 64px;
    height: 64px;
}

.token-upload-list {
    height: calc(100% - 78px);
}

.token-upload-name {
    width: 90%;
}