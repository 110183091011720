.subscription-settings {
    margin: 1rem;
    color:#647682;
    text-align: left;

    max-width: 512px;
    margin: 0 auto;
}

.subscription-settings label {
    color: #BFC9CE;
}

.subscription-settings-left {
    width: 100%;
    margin-bottom: 1rem;
}

.subscription-settings-right {
    width: 100%;
}

@media only screen and (min-width: 769px) {
    .subscription-settings-left {
        width: 30%;
        margin-right: 5%
    }
    
    .subscription-settings-right {
        width: 65%;
    }

}

/* Tablet and lower */
@media only screen and (max-width: 769px) {
    .billing-history-container {
        margin-top: 1rem;
    }
}

.billing-history-table {
    width: 100%;
    text-align: center;
    font-size: 0.6rem;
    border-collapse: collapse;
}

.billing-history-table thead {
    font-weight: bold;
}

.billing-history-table tbody tr{
    border-top: 1px solid #CCD5DB;
}

.billing-history-table td{
    padding: 0.75rem 0;
}


.billing-history-inner {
    min-height: 200px;
}

.billing-history-inner .nothingToSee p {
    font-size: 0.75rem;
    text-align: center;
}

.billing-history-inner .nothingToSee .Icon {
    width: 32px;
    height: 32px;
}

.billing-history-footer {
    font-size: 0.5rem;
    text-align: center;
    margin-top: 1rem;
}

.card.premium-promo-container .coming-soon {
    background-color: #79BF71;
    border-radius: 12px;
    padding: 1rem;
    margin: 1rem;
}

.card.premium-promo-container .coming-soon p {
    font-size: 0.8rem;
    margin-top: 0.5rem;
}

.card.premium-promo-container label {
    color: #46916E;
}

.upcomming-charge-date {
    font-size: 0.75rem;
    color: #ABB6BF;
    font-weight: bold;
}

.upcomming-charge-price {
    font-size: 2rem;
    color: #647682;
    line-height: 2rem;
}

.upcomming-charge-price span {
    font-size: 0.75rem;
}