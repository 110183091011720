.f-ice {fill: #f2f9fd;}
.f-light-blue {fill: #b4e1e9;}
.f-blue {fill: #6EADC6;}
.f-dark-blue {fill: #7faabc;}
.f-midnight-blue {fill: #404d55;}
.f-red {fill: #e17e7e;}
.f-light-red {fill: #EC9898;}
.f-dark-red {fill: #DF5353;}
.f-green {fill: #9bd095;}
.f-light-green {fill: #BCE0B8;}
.f-dark-green {fill: #87C77F;}
.f-yellow {fill: #ffedd6;}
.f-light-yellow {fill: #FFF6EB;}
.f-dark-yellow {fill: #FFDCAD;}
.f-cyan {fill: #b4e1e9;}
.f-light-cyan {fill: #f2f9fd;}
.f-dark-cyan {fill: #90D3DF;}

.f-white {fill: #FBFBFB;}
.f-shade-1 {fill: #f5f9fa;}
.f-shade-2 {fill: #eceff3;}
.f-shade-3 {fill: #dee1e8;}
.f-shade-4 {fill: #ccd5db;}
.f-shade-5 {fill: #abb6bf;}
.f-shade-6, .f-main {fill: #647682;}
.f-shade-7 {fill: #465058;}
.f-shade-8 {fill: #333c43;}
.f-shade-9 {fill: #21262c;}


/* Forced Dark Mode */
.force-dark-mode .f-white {fill: #21262c;}
.force-dark-mode .f-shade-1 {fill: #333c43;}
.force-dark-mode .f-shade-2 {fill: #465058;}
.force-dark-mode .f-shade-3, .force-dark-mode .f-main {fill: #647682;}
.force-dark-mode .f-shade-4 {fill: #abb6bf;}
.force-dark-mode .f-shade-5 {fill: #ccd5db;}
.force-dark-mode .f-shade-6 {fill: #dee1e8;}
.force-dark-mode .f-shade-7 {fill: #eceff3;}
.force-dark-mode .f-shade-8 {fill: #f5f9fa;}
.force-dark-mode .f-shade-9 {fill: #FBFBFB;}

/* Dark Mode */
@media (prefers-color-scheme: dark) {    
    .f-white {fill: #21262c;}
    .f-shade-1 {fill: #333c43;}
    .f-shade-2 {fill: #465058;}
    .f-shade-3, .f-main {fill: #647682;}
    .f-shade-4 {fill: #abb6bf;}
    .f-shade-5 {fill: #ccd5db;}
    .f-shade-6 {fill: #dee1e8;}
    .f-shade-7 {fill: #eceff3;}
    .f-shade-8 {fill: #f5f9fa;}
    .f-shade-9 {fill: #FBFBFB;}

    /* Forced Light theme in dark mode */
    .force-light-mode .f-white {fill: #FBFBFB;}
    .force-light-mode .f-shade-1 {fill: #f5f9fa;}
    .force-light-mode .f-shade-2 {fill: #eceff3;}
    .force-light-mode .f-shade-3, .force-light-mode .f-main {fill: #dee1e8;}
    .force-light-mode .f-shade-4 {fill: #ccd5db;}
    .force-light-mode .f-shade-5 {fill: #abb6bf;}
    .force-light-mode .f-shade-6 {fill: #647682;}
    .force-light-mode .f-shade-7 {fill: #465058;}
    .force-light-mode .f-shade-8 {fill: #333c43;}
    .force-light-mode .f-shade-9 {fill: #21262c;}
}