.placingToken {
    position: absolute;
    z-index: 1000000000000;
    background-size: cover;
    pointer-events: none;
}

.pasteIndicator {
    position: absolute;
    z-index: 10000000000;
}

.pasteTokenDisplay {
    position: absolute;
    opacity: 0.5;
    pointer-events: none;
}