.iconButton {
    background: none;
    border: none;
    box-shadow: none;
    color: #21262c;
    stroke: #21262c;
}

.iconButton:hover .Icon svg line, .iconButton:hover .Icon svg g{
    stroke: #647682;
}

/* .iconButton:hover { */
    /* background: none; */
    /* box-shadow: none; */
    /* transform: none; */
/* } */

/* Forced Dark Mode */
.force-dark-mode .iconButton {stroke: #CCD5DB;}

/* Dark Mode */
@media (prefers-color-scheme: dark) {    
    .iconButton {stroke: #CCD5DB;}

    /* Forced Light theme in dark mode */
    .force-light-mode .iconButton {stroke: #21262c;}
}