.nothingToSee{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.nothingToSee .Icon {
    width: 64px;
    height: 64px;
}

.nothingToSee__icon {
    font-size: 1.5rem;
}