.chat {
    position: absolute;
    bottom: 16px;
    left: 8px;
    z-index: 100000001;
    transition: left 0.3s ease-out, bottom 0.3s ease-out;
}

.chat-container {
    display: flex;
    position: absolute;
    opacity: 0;
    width: 0px;
    height: 0px;
    bottom: 24px;
    left: 24px;
    pointer-events: none;

    border-radius: 12px;

    z-index: -1;

    transition: width 0.3s ease-out, height 0.3s ease-out, opacity 0s ease-out 0.3s, background-color 0.3s ease-out 0.3s;
}

.chat.open {
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: none;
}

.chat.open .chat-container {
    opacity: 1;
    pointer-events: all;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;
    transition: width 0.3s ease-out, height 0.3s ease-out, opacity 0s ease-out, background-color 0.3s ease-out 0.3s;
}

.chat-inner {
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0s;
    width: 100%;
    height: calc(100% - 2rem);
    border-radius: 12px;
}

.chat.open .chat-inner {
    opacity: 1;
    transition: opacity 0.3s ease-out 0.3s;
}

.chat-button {
    position: absolute;
    bottom: 8px;
    left: 8px;
    transition: left 0.3s ease-out, bottom 0.3s ease-out;
}

.chat-header {
    display: flex;
    opacity: 0;
    align-items: center;
    height: 2rem;
    border-radius: 10px 10px 0 0;

    border-bottom: 1px solid;
    overflow: hidden;
    transition: opacity 0s;
}

.chat-header p {
    text-transform: capitalize;
    vertical-align: middle;
    padding-left: 1rem;
}

.chat.open .chat-header {
    opacity: 1;
    transition: opacity 0.3s ease-out 0.3s;
}

.small-close-button {
    position: absolute;
    right: 0;
    top: 0;
    padding: 10px;
    width: 32px;
    height: 32px;
    stroke: #647682;
    stroke-width: 3px;
    stroke-linecap: round;
}

.small-close-button:hover {
    transform: none;
    stroke: #3a3a3a;
}

.chat-input-container {
    position: absolute;
    right: 0;
    bottom: 0.5rem;
    width: calc(100% - 32px);
    padding: 0 1rem;
}

.chat-text-input {
    margin-left: 2rem;
    flex: 1 1;
}

@media only screen and (min-width: 769px) {
    .chat {
        z-index: 0;
    }

    .chat.open {
        top: auto;
        left: 8px;
        bottom: 16px;
    }

    .chat-container {
        border: 2px solid;
    }

    .chat.open .chat-container {
        top: auto;
        bottom: 24px;
        left: 24px;
        width: 400px;
        height: 460px;
        border-radius: 12px;
    }

    .chat-button {
        bottom: 0;
        transform: translateY(0px);
    }

    .chat-text-input {
        flex: 1 1;
        margin-left: 0;
    }
}

.chat-input-container.loading,
.chat-input-container.loading .button-container,
.chat-input-container.loading .button {
    pointer-events: none;
}

.chat-input-container.loading {
    opacity: 0.5;
}

.chat-send-button {
    width: 32px;
    height: 32px;
    border-width: 2px;
    margin-left: 0.5rem;
    padding: 8px;
}

.chat-send-button svg {
    stroke-width: 12px;
    stroke-linecap: round;
    stroke-linejoin: round;
}

.chat-text-input label {
    display: none;
}

.chat-messages-scroll-box {
    width: 100%;
    height: calc(100% - 52px);
    overflow: auto;
    display: flex;
    flex-direction: column-reverse;
}

.chat-messages-container {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    min-height: 100%;
    padding: 0.5rem 0 0.5rem 0;
}

@keyframes chat-message-slide-in {
    from {
        max-height: 0;
        margin: 0;
        transform: translateX(-100%);
    }

    to {
        max-height: 100%;
        margin-top: 0.5rem;
        transform: translateX(0%);
    }
}

.message-list-container {
    display: flex;
    flex-direction: column-reverse;
    position: relative;
}

.chat-message-container {
    display: flex;
    padding: 0 1rem;
    margin-top: 0.5rem;
    animation: none;
}

.chat-message-container:first-child {
    max-height: 0;
    margin: 0;
    transform: translateX(-100%);
    animation: chat-message-slide-in 0.3s ease-out;
    animation-fill-mode: forwards;
}

.chat-old .chat-message-container:first-child {
    animation: none;
    max-height: 100%;
    margin-top: 0.5rem;
    transform: translateX(0%);
}

.chat-message-inner {
    padding: 0.75rem 0;
    width: 100%;
    border-radius: 12px;
}

.chat-message-inner.roll {
    position: relative;
    margin-top: 12px;
}

.chat-message-inner.roll.red {
    background: #f3d2d2;
}

.chat-message-inner.roll.yellow {
    background: #fff3e2;
}

.chat-message-inner.roll.blue {
    background: #F2F9FD;
}

.chat-message-inner.roll.green {
    background: #e1f5de;
}

/* Forced Dark Mode */
.force-dark-mode .chat-message-inner.roll.red {
    background: none;
    border: 3px solid #e17e7e;
}

.force-dark-mode .chat-message-inner.roll.yellow {
    background: none;
    border: 3px solid #ffedd6;
}

.force-dark-mode .chat-message-inner.roll.blue {
    background: none;
    border: 3px solid #6EADC6;
}

.force-dark-mode .chat-message-inner.roll.green {
    background: none;
    border: 3px solid #9bd095;
}

/* Dark Mode */
@media (prefers-color-scheme: dark) {
    .chat-message-inner.roll.red {
        background: none;
        border: 3px solid #e17e7e;
    }

    .chat-message-inner.roll.yellow {
        background: none;
        border: 3px solid #ffedd6;
    }

    .chat-message-inner.roll.blue {
        background: none;
        border: 3px solid #6EADC6;
    }

    .chat-message-inner.roll.green {
        background: none;
        border: 3px solid #9bd095;
    }

    /* Forced Light theme in dark mode */
    .force-light-mode .chat-message-inner.roll.red {
        background: #f3d2d2;
    }

    .force-light-mode .chat-message-inner.roll.yellow {
        background: #fff3e2;
    }

    .force-light-mode .chat-message-inner.roll.blue {
        background: #F2F9FD;
    }

    .force-light-mode .chat-message-inner.roll.green {
        background: #e1f5de;
    }
}


.chat-message-author {
    display: inline;
    font-size: 12px;
    color: #647682;
    line-height: 8px;
}

.chat-message-author-icon {
    margin-right: 0.5rem;
    min-width: 24px;
    width: 24px;
    height: 24px;
    margin-top: 10px;
    flex-basis: 24px;
    font-size: 0.75rem;
    border-width: 1px;
}

.chat-message {
    overflow-wrap: anywhere;
}

.chat-message img {
    max-width: 100%;
}

.chat-message-inner.roll .chat-message {
    text-align: center;
    padding: 0 0.75rem;
}

.chat-message-inner.roll .chat-message p {
    color: #7FAABC;
    font-size: 0.8rem;
}

.chat-message-inner.roll .chat-message strong {
    font-weight: normal;
    color: #647682;
    font-size: 0.8rem;
}

.chat-message-inner.roll .chat-message strong:first-child,
.chat-message-inner.roll .chat-message strong:last-child {
    font-weight: bold;
}

.chat-message-inner.roll .chat-message strong:last-child {
    font-size: 1rem;
}

.chat-message-timestamp {
    display: inline;
    font-size: 10px;
    margin-left: 8px;
    color: #ABB6BF;
    opacity: 0;
}

.chat-message-inner:hover .chat-message-timestamp {
    opacity: 1;
}

.chat-message-inner.roll .chat-message-timestamp {
    position: absolute;
    top: -16px;
    left: 8px;
}

.chat-user-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 42px;
    padding-top: 0.25rem;
    padding-bottom: 4.5rem;
    border-radius: 12px 0 0 0;

    opacity: 0;
    transition: opacity 0s;
}

@media only screen and (min-width: 769px) {
    .chat-user-list {
        padding-bottom: 3.5rem;
        width: 32px;
    }
}

.chat.open .chat-user-list {
    opacity: 1;
    transition: opacity 0.3s ease-out 0.3s;
}

.chat-user-list-inner {
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    overflow: auto;
}

.chat-user-list-inner,
.volume-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.chat-user-list .iconButton,
.voice-controls .iconButton,
.chat-user-list-dialogue .iconButton {
    padding: 0;
    height: 26px;
    width: 26px;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    fill: #647682;
    stroke: none;
    border-radius: 8px;
}

.chat-user-list .iconButton:hover svg g,
.chat-user-list-dialogue .iconButton:hover svg g {
    stroke: none;
}

.chat-user-list .iconButton:hover,
.voice-controls .iconButton:hover,
.chat-user-list-dialogue .iconButton:hover {
    fill: #aaaaaa;
    stroke: none;
}

@media only screen and (min-width: 769px) {

    .chat-user-list .iconButton,
    .voice-controls .iconButton,
    .chat-user-list-dialogue .iconButton {
        margin-top: 0.25rem;
    }
}

.iconButton.red {
    fill: white;

    background-color: #E17E7E;
}

.iconButton.red:hover {
    fill: white;
    stroke: white;
    background-color: #D06161;
}

.chat-user-list .end-call-button {
    fill: white;
    stroke: none;
    background-color: #E17E7E;
}

.chat-user-list .end-call-button:hover {
    fill: white;
    stroke: none;
    background-color: #D06161;
}

.volume-controls {
    display: flex;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-out;
}

.display-voice .volume-controls {
    opacity: 1;
    pointer-events: all;
}

.volume-controls .voice-settings-button {
    padding-top: 2px;
    display: none;
}

.chat-inner .volume-controls .voice-settings-button {
    display: block;
}

.voice-settings-button,
.voice-settings-button .settings-icon {
    height: 24px;
}

.voice-settings-button .settings-icon svg {
    height: 17px;
}

.volume-controls .Icon,
.voice-controls .Icon {
    width: 32px;
    height: 32px;
}


.volume-controls .red .Icon,
.volume-controls .end-call-button .Icon {
    width: 16px;
    height: 16px;
}

.connect-to-voice {
    padding: 8px;
}

.chat-user-icon-crown {
    position: absolute;
    bottom: 15px;
    right: -7px;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 100%;
    width: 12px;
    height: 12px;
    pointer-events: none;
}

.chat-user-icon-crown img {
    width: 8px;
    height: 8px;
}

.chat-user-list-icon {
    cursor: pointer;
    margin: 0 0.25rem;
    position: relative;
    margin: 3px 0;
    font-size: 10px;

}

.user-icon-circle.chat-user-list-icon,
.user-icon-circle.chat-message-author-icon {
    width: 24px;
    height: 24px;
    font-size: 12px;
}

@media only screen and (min-width: 769px) {
    .chat-user-list-icon {
        width: 23px;
        height: 23px;
    }
}

.chat-user-list-icon:focus,
.chat-user-list-icon:active {
    outline: none;
}

.chat-user-list-icon.loading {
    border: 0;
}

.chat-user-self-icon {
    transform: translate(-1px, -1px);
    border-width: 2px;
    border: 2px solid #647682;
}

.usersAreTyping {
    position: absolute;
    bottom: 58px;
    right: 45px;
    display: flex;
    color: #647682;
    font-size: 0.5rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
    font-weight: bold;
}

.typing-dots {
    margin-left: 0.25rem;
}

.typing-dots .dot {
    display: inline-block;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    margin-right: 2px;
    background: #647682;
    animation: wave 1.3s linear infinite;
}

.typing-dots .dot:nth-child(2) {
    animation-delay: -1.1s;
}

.typing-dots .dot:nth-child(3) {
    animation-delay: -0.9s;
}

@keyframes wave {

    0%,
    60%,
    100% {
        transform: initial;
    }

    30% {
        transform: translateY(-3px);
    }
}


.chat-user-list-dialogue {
    position: absolute;
    white-space: nowrap;
    padding: 0.25rem 0.5rem;
    top: 0;
    left: 0;
    opacity: 0;
    transform: scale(0);
    transform-origin: top left;
    border: 1px solid;
    border-radius: 8px;

    transition: opacity 0.1s ease-out, transform 0.1s ease-out;
    z-index: 10000000000000;

    pointer-events: all;
}

.chat-user-list-dialogue .Slider {
    margin: 12px 0;
}

.chat-user-list-dialogue .iconButton {
    margin-top: 0;
}

.chat-user-list-dialogue-fold .Icon {
    width: 10px;
    height: 14px;
    margin-right: 0.5rem;
}

.chat-user-list-icon:hover .chat-user-list-dialogue,
.chat-user-list-dialogue.open {
    opacity: 1;
    transform: scale(1);
}

.chat-user-list-dialogue-fold {
    display: none;
    align-items: center;
    justify-content: center;
}

.chat-user-list-dialogue.open .chat-user-list-dialogue-fold {
    display: flex;
}

.voice-controls-user-list {
    display: flex;
    height: 32px;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    margin-left: 0.5rem;

    pointer-events: all;
}

.voice-controls-user-list .user-icon-circle {
    margin-right: 0.5rem;
    width: 24px;
    height: 24px;
}

.voice-controls-user-list-others {
    display: flex;
    margin-left: 0.5rem
}

.voice-controls-user-list .user-icon-circle .chat-user-icon-crown {
    bottom: 15px;
    right: -7px;
}

.user-icon-circle.voice-activated {
    border-color: #7faabc;
    border-width: 2px;
    position: relative;
}

.chatUserIcon-muteIcon {
    width: 70%;
    height: 70%;
    margin-right: 1px;
}

.chat:hover .chat-badge.active {
    transform: translateY(-2px) scale(1.01);
}

.chat:hover .chat-badge {
    transform: scale(0);
}

.chat-badge {
    position: absolute;
    width: 24px;
    height: 24px;
    top: -56px;
    right: -68px;

    font-size: 0.75rem;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 100%;

    z-index: 1000;
    color: white;
    background: #F48B8B;

    transform: scale(0);
    transition: 0.1s ease-out transform;
}

.chat-badge.active {
    transform: scale(1);
}

.call-separator {
    width: 100%;
    margin: 0.5rem 0;
}

.call-separator-icon {
    position: relative;
}

.call-separator-line {
    width: 80%;
    margin: 0 auto;
    border-bottom: 1px solid #B7B7B7;
}

.call-separator svg {
    position: absolute;
    top: -8px;
    left: 8px;
    width: 16px;
    height: 16px;
    fill: #B7B7B7;
    background: #F5F9FA;
    padding: 4px;
}

.chat-connection-status {
    position: absolute;
    bottom: 0;
    z-index: -1;

    margin-left: 2rem;
    padding: 0 1rem 0 2rem;
    border-radius: 12px;

    transform-origin: left;
    transform: scaleX(0);
    animation: chat-connection-status 0.3s ease-out 2s forwards;
}

.chat-connection-status p {
    text-transform: capitalize;
}

@keyframes chat-connection-status {
    from {
        transform: scaleX(0);
    }

    to {
        transform: scaleX(1);
    }
}

/* Forced Dark Mode */
.force-dark-mode .call-separator svg {
    background-color: #333c43;
}

/* Dark Mode */
@media (prefers-color-scheme: dark) {
    .call-separator svg {
        background-color: #333c43;
    }

    /* Forced Light theme in dark mode */
    .force-light-mode .call-separator svg {
        background-color: #f5f9fa;
    }
}