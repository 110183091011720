.min-width-half {
    min-width: 0.5rem;
}

.min-width-three-fourths {
    min-width: 0.75rem;
}

.min-width-1 {
    min-width: 1rem;
}

.min-width-2 {
    min-width: 2rem;
}

.min-width-3 {
    min-width: 3rem;
}

.min-width-4 {
    min-width: 4rem;
}

.min-width-5 {
    min-width: 5rem;
}

.min-width-6 {
    min-width: 6rem;
}

.min-width-7 {
    min-width: 7rem;
}

.min-width-8 {
    min-width: 8rem;
}

.min-width-9 {
    min-width: 9rem;
}

.min-width-10 {
    min-width: 10rem;
}

.min-height-half {
    min-height: 0.5rem;
}

.min-height-1 {
    min-height: 1rem;
}

.min-height-2 {
    min-height: 2rem;
}

.min-height-3 {
    min-height: 3rem;
}

.min-height-4 {
    min-height: 4rem;
}

.min-height-5 {
    min-height: 5rem;
}

.min-height-6 {
    min-height: 6rem;
}

.min-height-7 {
    min-height: 7rem;
}

.min-height-8 {
    min-height: 8rem;
}

.min-height-9 {
    min-height: 9rem;
}

.min-height-10 {
    min-height: 10rem;
}

.max-width-half {
    max-width: 0.5rem;
}

.max-width-1 {
    max-width: 1rem;
}

.max-width-2 {
    max-width: 2rem;
}

.max-width-3 {
    max-width: 3rem;
}

.max-width-4 {
    max-width: 4rem;
}

.max-width-5 {
    max-width: 5rem;
}

.max-width-6 {
    max-width: 6rem;
}

.max-width-7 {
    max-width: 7rem;
}

.max-width-8 {
    max-width: 8rem;
}

.max-width-9 {
    max-width: 9rem;
}

.max-width-10 {
    max-width: 10rem;
}

.max-width-25 {
    max-width: 25rem;
}

.max-height-half {
    max-height: 0.5rem;
}

.max-height-1 {
    max-height: 1rem;
}

.max-height-2 {
    max-height: 2rem;
}

.max-height-3 {
    max-height: 3rem;
}

.max-height-4 {
    max-height: 4rem;
}

.max-height-5 {
    max-height: 5rem;
}

.max-height-6 {
    max-height: 6rem;
}

.max-height-7 {
    max-height: 7rem;
}

.max-height-8 {
    max-height: 8rem;
}

.max-height-9 {
    max-height: 9rem;
}

.max-height-10 {
    max-height: 10rem;
}

.width-half {
    width: 0.5rem;
}

.width-1 {
    width: 1rem;
}

.width-2 {
    width: 2rem;
}

.width-3 {
    width: 3rem;
}

.width-4 {
    width: 4rem;
}

.width-5 {
    width: 5rem;
}

.width-6 {
    width: 6rem;
}

.width-7 {
    width: 7rem;
}

.width-8 {
    width: 8rem;
}

.width-9 {
    width: 9rem;
}

.width-10 {
    width: 10rem;
}

.width-10dot5 {
    width: 10.5rem;
}

.width-11 {
    width: 11rem;
}

.width-12 {
    width: 12rem;
}

.height-half {
    height: 0.5rem;
}

.height-1 {
    height: 1rem;
}

.height-2 {
    height: 2rem;
}

.height-3 {
    height: 3rem;
}

.height-4 {
    height: 4rem;
}

.height-5 {
    height: 5rem;
}

.height-6 {
    height: 6rem;
}

.height-7 {
    height: 7rem;
}

.height-8 {
    height: 8rem;
}

.height-9 {
    height: 9rem;
}

.height-10 {
    height: 10rem;
}

.height-11 {
    height: 11rem;
}

.height-12 {
    height: 12rem;
}

.size-half {
    width: 0.5rem;
    height: 0.5rem;
}

.size-75 {
    width: 0.75rem;
    height: 0.75rem;
}

.size-1 {
    width: 1rem;
    height: 1rem;
}

.size-1and1half,
.size-1dot5 {
    width: 1.5rem;
    height: 1.5rem;
}

.size-2 {
    width: 2rem;
    height: 2rem;
}

.size-3 {
    width: 3rem;
    height: 3rem;
}

.size-3dot5 {
    width: 3.5rem;
    height: 3.5rem;
}

.size-4 {
    width: 4rem;
    height: 4rem;
}

.size-5 {
    width: 5rem;
    height: 5rem;
}

.size-6 {
    width: 6rem;
    height: 6rem;
}

.size-7 {
    width: 7rem;
    height: 7rem;
}

.size-8 {
    width: 8rem;
    height: 8rem;
}

.size-9 {
    width: 9rem;
    height: 9rem;
}

.size-10 {
    width: 10rem;
    height: 10rem;
}

.min-size-75 {
    min-width: 0.75rem;
    min-width: 0.75rem;
}

.min-size-half {
    min-width: 0.5rem;
    min-height: 0.5rem;
}

.min-size-1 {
    min-width: 1rem;
    min-height: 1rem;
}

.min-size-1and1half,
.min-size-1dot5 {
    min-width: 1.5rem;
    min-height: 1.5rem;
}

.min-size-2 {
    min-width: 2rem;
    min-height: 2rem;
}

.min-size-3 {
    min-width: 3rem;
    min-height: 3rem;
}

.min-size-4 {
    min-width: 4rem;
    min-height: 4rem;
}

.min-size-5 {
    min-width: 5rem;
    min-height: 5rem;
}

.min-size-6 {
    min-width: 6rem;
    min-height: 6rem;
}

.min-size-7 {
    min-width: 7rem;
    min-height: 7rem;
}

.min-size-8 {
    min-width: 8rem;
    min-height: 8rem;
}

.min-size-9 {
    min-width: 9rem;
    min-height: 9rem;
}

.min-size-10 {
    min-width: 10rem;
    min-height: 10rem;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.three-fourths-width {
    width: 75%;
}

.three-fourths-height {
    height: 75%;
}

.half-height {
    height: 50%;
}

.half-width {
    width: 50%;
}

.quarter-height {
    height: 25%;
}

.full-width-mobile {
    width: 100%;
}

.full-min-width {
    min-width: 100%;
}

.full-min-height {
    min-height: 100%;
}

.height-80 {
    height: 80%;
}

.object-fit-cover {
    object-fit: cover;
}

.object-fit-contain {
    object-fit: contain;
}