.blobs{
    filter:url('#goo');
    position: absolute;
    top:0;
    left: 0;
}

.blob{
    position: absolute;
    border-radius: 100%;
    background-color: #255979;

    width: 256px;
    height: 256px;

    animation: goo 10s linear;
    animation-iteration-count: infinite;

    transition: width 1s, height 1s;
}

.blob.dark{background-color: #1F506E;}

.blob:nth-child(1){
    top: -14px;
    left: -18px;
    width: 100px;
    height: 100px;
    animation-duration: 25s;
}

.blob:nth-child(2){
    top: 14px;
    left: 138px;
    width: 164px;
    height: 164px;
    animation-duration: 40s;
}

.blob:nth-child(3){
    top: -16px;
    left: 30px;
    width: 196px;
    height: 196px;
    animation-duration: 20s;
}

.blobs.reverse .blob:nth-child(1){
    top: 150px;
    left: -48px;
    width: 128px;
    height: 128px;
    animation-duration: 25s;
}

.blobs.reverse .blob:nth-child(2){
    top: -50px;
    left: 0px;
    width: 500px;
    height: 450px;
    animation-duration: 40s;
}

.blobs.reverse .blob:nth-child(3){
    top: -48px;
    left: 300px;
    animation-duration: 20s;
}

.landing-feature[data-scroll="out"] .blob{
    width: 0px;
    height: 0px;
}

.landing-feature[data-scroll="out"] .blobs.reverse .blob{
    width: 0px;
    height: 0px;
}

@keyframes goo {
    0%{
        transform: translate(0, 0) scale(1, 1);
    }

    25%{
        transform: translate(-10px, 10px) scale(1.08, 0.98);
    }

    50%{
        transform: translate(10px, -10px) scale(1, 0.98);
    }

    75%{
        transform: translate(10px, 10px) scale(0.99, 1.01);
    }

    100%{
        transform: translate(0, 0) scale(1, 1);
    }
}

@media only screen and (min-width: 425px) {
    .blob:nth-child(1){
        top: -14px;
        left: -18px;
        width: 120px;
        height: 120px;
        animation-duration: 25s;
    }
    
    .blob:nth-child(2){
        top: 24px;
        left: 148px;
        width: 184px;
        height: 184px;
        animation-duration: 40s;
    }
    
    .blob:nth-child(3){
        top: -16px;
        left: 30px;
        width: 216px;
        height: 216px;
        animation-duration: 20s;
    }
}

@media only screen and (min-width: 500px) {
    .blob:nth-child(1){
        top: -14px;
        left: -18px;
        width: 120px;
        height: 120px;
        animation-duration: 25s;
    }
    
    .blob:nth-child(2){
        top: 74px;
        left: 238px;
        width: 184px;
        height: 184px;
        animation-duration: 40s;
    }
    
    .blob:nth-child(3){
        top: -16px;
        left: 30px;
        width: 286px;
        height: 286px;
        animation-duration: 20s;
    }
}


/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 650px) {
    .blob:nth-child(1){
        top: -64px;
        left: -48px;
        width: 400px;
        height: 400px;
        animation-duration: 25s;
    }
    
    .blob:nth-child(2){
        top: -42px;
        left: 218px;
        animation-duration: 40s;
    }
    
    .blob:nth-child(3){
        top: 64px;
        left: 250px;
        animation-duration: 20s;
    }
}

/* Medium devices (landscape tablets, 769px and up) */
@media only screen and (min-width: 769px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1440px) {
    
}