.toggle {
    cursor: pointer;
    position: relative;
    border-radius: 16px;
    border: 1px solid #647682;
}

.toggleEntry {
    font-size: 0.9rem;
    padding: 0.25rem 0.5rem;
    margin: 0;
    border: none;
    border-radius: 16px;
    font-weight: bold;
    color: #647682;
    background-color: transparent;
    transition: color 0.3s ease-out, background-color 0.3s ease-out;
}

.toggleEntry.fullWidthButtons {
    width: 100%;
}

.toggleEntry:first-child {
    margin-right: 0.25rem;
}

.toggleEntry.active {
    background-color: #647682;
    color: white;
}

.toggleEntry:active,
.toggleEntry:focus {
    outline: none;
}