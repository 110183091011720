.diceHistory {
    padding-top: 2rem;
    pointer-events: all;

    overflow-y: auto;
    direction: rtl;

    opacity: 1;

    transition: opacity 0.3s ease-out, max-height 0.3s ease-out;
}

.diceHistory__separator {
    padding-top: 1rem;
    width: 25%;
    margin-left: auto;
    border-bottom: 1px solid #ccd5db;
}

.diceHistory__container {
    position: absolute;
    top: 0;
    right: 0;
    transition: right 0.3s ease-out;
    z-index: -2;
}

.diceHistory__container.inFullScreen {
    top: 38px;
}

.diceHistory.active {
    opacity: 1;
}

.diceHistoryEntry {
    position: relative;
    justify-content: space-between;
    min-width: 320px;
    border-radius: 12px 0 0 12px;
    padding: 0.5rem 1.5rem;
    background: #F2F9FD;
    color: #7FAABC;

    margin-top: 1rem;
    border: 3px solid;
    border-right: none;
    direction: ltr;

    position: relative;
    left: 350px;
    background-size: cover;
    animation: diceHistorySlideIn 0.3s ease-out 0.3s forwards;
}

.diceHistory .remote {
    border-style: dotted;
    border-right: none;
}

@keyframes diceHistorySlideIn {
    0% {
        left: 350px;
    }

    100% {
        left: 0px;
    }
}

.diceHistoryEntry p {
    text-transform: capitalize;
}

.diceHistoryEntry .totals {
    padding: 0.25rem 1rem;
    border-radius: 12px;
    background: #D7EBF7;
    font-size: 1.5rem;
    font-weight: bold;
}

.diceHistoryEntry.failure .totals {
    background: none;
    border: 3px solid #D7EBF7;
}

.diceHistoryEntry__diceList {
    position: relative;
    margin: 0.25rem;
    margin-bottom: 0.25rem;
}

.diceHistoryEntry__diceList.group {
    margin-bottom: 0;
    padding-bottom: 0.5rem;
    margin-bottom: -0.25rem;
    border-radius: 8px 8px 0px 0px;
}

.diceHistoryEntry__count {
    font-size: 0.75rem;
    font-weight: 700;
    position: absolute;
    top: 0;
    left: 100%;
}

.diceHistoryEntry__addOrSub {
    font-size: 1.3rem;
    font-weight: bold;
}

.diceHistoryEntry__left {
    width: 100%;
    max-width: 320px;
}

.diceHistoryEntry .explosion {
    border-radius: 12px;
    margin: 0.25rem;
    padding: 0.25rem;
    margin-top: 0;
    padding-top: 0;
    align-items: center;
    flex-wrap: wrap;
}

.diceHistoryEntry .explosion .diceHistoryEntry__diceList {
    margin-bottom: 0;
}

.diceValueIcon.D4~.diceHistoryEntry__count {
    left: 80%;
}

.diceValueIcon.D6~.diceHistoryEntry__count {
    left: 105%;
}

.diceHistoryEntry__diceContainer {
    flex-wrap: wrap;
    max-width: 200px;
}

.diceHistoryEntry__diceContainer.group {
    /* display: none; */
    opacity: 0;
    height: 100%;
    max-height: 0px;
    overflow: hidden;

    transition: max-height 0.3s ease-out, opacity 0.3s ease-out, margin 0.3ms ease-out;
}

.diceHistoryEntry__diceContainer.group.open {
    margin-bottom: 0.5rem;
    max-height: 300px;
    opacity: 1;
}

.diceHistoryEntry__left.in-progress .diceValueIcon {
    animation: diceRolling 1s ease-in-out infinite;
}

.diceHistoryEntry__left.in-progress .diceValueIcon p {
    display: none;
}

.diceHistoryEntry__left.in-progress .diceHistoryEntry__message:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
    animation: ellipsis steps(4, end) 900ms infinite;
    content: "\2026";
    /* ascii code for the ellipsis character */
    width: 0px;
}

@keyframes diceRolling {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes ellipsis {
    to {
        width: 20px;
    }
}

@keyframes grow {
    from {
        max-height: 0px;
        opacity: 0;
    }

    to {
        max-height: 1000px;
        opacity: 1;
    }
}

.diceHistoryEntry__message .privateLabel {
    color: #abb6bf;
    max-width: 200px;
    overflow-y: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.diceHistoryEntry .Icon.arrow-right {
    width: 16px;
    height: 16px;
    margin-top: 0.25rem;
}

@media only screen and (min-width: 769px) {
    .diceHistory {
        max-height: 320px;
        opacity: 0.3;
    }
}


.diceHistoryEntry.red {
    background: rgba(255, 223, 222, 0.8);
    color: #BF4747;
    border-color: #E69E9D;
}

.diceHistoryEntry.blue {
    background: rgba(242, 249, 253, 0.8);
    color: #457386;
    border-color: #D7EBF7;
}

.diceHistoryEntry.green {
    background: rgba(234, 255, 232, 0.8);
    color: #4E9347;
    border-color: #9BD095;
}

.diceHistoryEntry.yellow {
    background: rgba(255, 249, 242, 0.8);
    color: #a45d00;
    border-color: #F7DCB9;
}

.diceHistoryEntry.lilac {
    background: rgba(211, 150, 255, 0.8);
    color: #5e00a3;
    border-color: #C06AFF;
}

.diceHistoryEntry.orange {
    background: rgba(255, 185, 90, 0.8);
    color: #7e4900;
    border-color: #EB9C30;
}

.diceHistoryEntry.black {
    background: rgba(37, 36, 36, 0.8);
    color: #BABABA;
    border-color: #656565;
}

.diceHistoryEntry.white {
    background: rgba(224, 224, 224, 0.8);
    color: #4E4E4E;
    border-color: #BCBCBC;
}

.diceHistoryEntry.stone {
    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../../assets/stone-background.png');
    color: white;
    border-color: #404d55;
}

.diceHistoryEntry.red .Icon.arrow-right {
    stroke: #BF4747;
}

.diceHistoryEntry.blue .Icon.arrow-right {
    stroke: #457386;
}

.diceHistoryEntry.green .Icon.arrow-right {
    stroke: #4E9347;
}

.diceHistoryEntry.yellow .Icon.arrow-right {
    stroke: #a45d00;
}

.diceHistoryEntry.stone .Icon.arrow-right {
    stroke: white;
}

.diceHistoryEntry.lilac .Icon.arrow-right {
    stroke: #5e00a3;
}

.diceHistoryEntry.orange .Icon.arrow-right {
    stroke: #7e4900;
}

.diceHistoryEntry.black .Icon.arrow-right {
    stroke: #656565;
}

.diceHistoryEntry.white .Icon.arrow-right {
    stroke: #4E4E4E;
}

.diceHistoryEntry.red .totals {
    background: #E69E9D;
}

.diceHistoryEntry.red.failure .totals {
    background: none;
    border: 3px solid #E69E9D
}

.diceHistoryEntry.blue .totals {
    background: #D7EBF7;
}

.diceHistoryEntry.blue.failure .totals {
    background: none;
    border: 3px solid #D7EBF7;
}

.diceHistoryEntry.green .totals {
    background: #9BD095;
}

.diceHistoryEntry.green.failure .totals {
    background: none;
    border: 3px solid #9BD095;
}

.diceHistoryEntry.yellow .totals {
    background: #F7DCB9;
}

.diceHistoryEntry.yellow.failure .totals {
    background: none;
    border: 3px solid #F7DCB9;
}

.diceHistoryEntry.stone .totals {
    background: #404d55;
}

.diceHistoryEntry.stone.failure .totals {
    background: none;
    border: 3px solid #404d55;
}

.diceHistoryEntry.lilac .totals {
    background: #C06AFF;
}

.diceHistoryEntry.lilac.failure .totals {
    background: none;
    border: 3px solid #C06AFF;
}

.diceHistoryEntry.orange .totals {
    background: #ffb95a;
}

.diceHistoryEntry.orange.failure .totals {
    background: none;
    border: 3px solid #ffb95a;
}

.diceHistoryEntry.black .totals {
    background: #656565;
}

.diceHistoryEntry.black.failure .totals {
    background: none;
    border: 3px solid #656565
}

.diceHistoryEntry.white .totals {
    background: #e0e0e0;
}

.diceHistoryEntry.white.failure .totals {
    background: none;
    border: 3px solid #e0e0e0
}

.diceHistoryEntry.red .explosion,
.diceHistoryEntry.red .group {
    background: #E69E9D;
}

.diceHistoryEntry.blue .explosion,
.diceHistoryEntry.blue .group {
    background: #D7EBF7;
}

.diceHistoryEntry.green .explosion,
.diceHistoryEntry.green .group {
    background: #9BD095;
}

.diceHistoryEntry.yellow .explosion,
.diceHistoryEntry.yellow .group {
    background: #F7DCB9;
}

.diceHistoryEntry.stone .explosion,
.diceHistoryEntry.stone .group {
    background: #404d55;
}

.diceHistoryEntry.lilac .explosion,
.diceHistoryEntry.lilac .group {
    background: #d396ff
}

.diceHistoryEntry.orange .explosion,
.diceHistoryEntry.orange .group {
    background: #ffb95a;
}

.diceHistoryEntry.black .explosion,
.diceHistoryEntry.black .group {
    background: #656565;
}

.diceHistoryEntry.white .explosion,
.diceHistoryEntry.white .group {
    background: #BCBCBC;
}

/* Forced Dark Mode */
.force-dark-mode .diceHistoryEntry.red {
    background: rgba(230, 158, 157, 0.8);
    color: #FFDFDE;
    border-color: #FFDFDE;
}

.force-dark-mode .diceHistoryEntry.red.failure .totals {
    background: none;
    border: 3px solid #E69E9D
}

.force-dark-mode .diceHistoryEntry.blue {
    background: rgba(127, 170, 188, 0.8);
    color: #457386;
    border-color: #7FAABC;
}

.force-dark-mode .diceHistoryEntry.blue.failure .totals {
    background: none;
    border: 3px solid #D7EBF7;
}

.force-dark-mode .diceHistoryEntry.green {
    background: rgba(155, 208, 149, 0.8);
    color: #EAFFE8;
    border-color: #9BD095;
}

.force-dark-mode .diceHistoryEntry.green.failure .totals {
    background: none;
    border: 3px solid #9BD095;
}

.force-dark-mode .diceHistoryEntry.yellow {
    background: rgba(255, 249, 242, 0.8);
    color: #a45d00;
    border-color: #F7DCB9;
}

.force-dark-mode .diceHistoryEntry.yellow.failure .totals {
    background: none;
    border: 3px solid #F7DCB9;
}

.force-dark-mode .diceHistoryEntry.lilac {
    background: rgba(211, 150, 255, 0.8);
    color: #5e00a3;
    border-color: #C06AFF;
}

.force-dark-mode .diceHistoryEntry.stone.failure .totals {
    background: none;
    border: 3px solid #404d55;
}

.force-dark-mode .diceHistoryEntry.orange {
    background: rgba(255, 185, 90, 0.8);
    color: #7e4900;
    border-color: #EB9C30;
}

.force-dark-mode .diceHistoryEntry.lilac.failure .totals {
    background: none;
    border: 3px solid #C06AFF;
}

.force-dark-mode .diceHistoryEntry.black {
    background: rgba(37, 36, 36, 0.8);
    color: #BABABA;
    border-color: #656565;
}

.force-dark-mode .diceHistoryEntry.orange.failure .totals {
    background: none;
    border: 3px solid #ffb95a;
}

.force-dark-mode .diceHistoryEntry.white {
    background: rgba(224, 224, 224, 0.8);
    color: #4E4E4E;
    border-color: #BCBCBC;
}

.force-dark-mode .diceHistoryEntry.black.failure .totals {
    background: none;
    border: 3px solid #656565
}

.force-dark-mode .diceHistoryEntry.red .Icon.arrow-right {
    stroke: #FFDFDE;
}

.force-dark-mode .diceHistoryEntry.blue .Icon.arrow-right {
    stroke: #457386;
}

.force-dark-mode .diceHistoryEntry.green .Icon.arrow-right {
    stroke: #EAFFE8;
}

.force-dark-mode .diceHistoryEntry.yellow .Icon.arrow-right {
    stroke: #a45d00;
}

.force-dark-mode .diceHistoryEntry.lilac .Icon.arrow-right {
    stroke: #5e00a3;
}

.force-dark-mode .diceHistoryEntry.orange .Icon.arrow-right {
    stroke: #7e4900;
}

.force-dark-mode .diceHistoryEntry.black .Icon.arrow-right {
    stroke: #BABABA;
}

.force-dark-mode .diceHistoryEntry.white .Icon.arrow-right {
    stroke: #4E4E4E;
}

.force-dark-mode .diceHistoryEntry.red .totals {
    background: #E69E9D;
}

.force-dark-mode .diceHistoryEntry.blue .totals {
    background: #7FAABC;
}

.force-dark-mode .diceHistoryEntry.green .totals {
    background: #9BD095;
}

.force-dark-mode .diceHistoryEntry.yellow .totals {
    background: #F7DCB9;
}

.force-dark-mode .diceHistoryEntry.lilac .totals {
    background: #d396ff;
}

.force-dark-mode .diceHistoryEntry.orange .totals {
    background: #ffb95a;
}

.force-dark-mode .diceHistoryEntry.black .totals {
    background: #656565;
}

.force-dark-mode .diceHistoryEntry.white .totals {
    background: #e0e0e0;
}

.force-dark-mode .diceHistoryEntry.red .explosion {
    background: #E69E9D;
}


.force-dark-mode .diceHistoryEntry.blue .explosion {
    background: #7FAABC;
}

.force-dark-mode .diceHistoryEntry.green .explosion {
    background: #9BD095;
}

.force-dark-mode .diceHistoryEntry.yellow .explosion {
    background: #F7DCB9;
}

.force-dark-mode .diceHistoryEntry.lilac .explosion {
    background: #d396ff;
}

.force-dark-mode .diceHistoryEntry.orange .explosion {
    background: #ffb95a;
}

.force-dark-mode .diceHistoryEntry.black .explosion {
    background: #656565;
}

.force-dark-mode .diceHistoryEntry.white .explosion {
    background: #BCBCBC;
}

/* Dark Mode */
@media (prefers-color-scheme: dark) {
    .diceHistoryEntry.red {
        background: rgba(230, 158, 157, 0.8);
        color: #FFDFDE;
        border-color: #FFDFDE;
    }

    .diceHistoryEntry.blue {
        background: rgba(127, 170, 188, 0.8);
        color: #457386;
        border-color: #7FAABC;
    }

    .diceHistoryEntry.green {
        background: rgba(155, 208, 149, 0.8);
        color: #EAFFE8;
        border-color: #9BD095;
    }

    .diceHistoryEntry.yellow {
        background: rgba(255, 249, 242, 0.8);
        color: #a45d00;
        border-color: #F7DCB9;
    }

    .diceHistoryEntry.lilac {
        background: rgba(211, 150, 255, 0.8);
        color: #5e00a3;
        border-color: #C06AFF;
    }

    .diceHistoryEntry.orange {
        background: rgba(255, 185, 90, 0.8);
        color: #7e4900;
        border-color: #EB9C30;
    }

    .diceHistoryEntry.black {
        background: rgba(37, 36, 36, 0.8);
        color: #BABABA;
        border-color: #656565;
    }

    .diceHistoryEntry.white {
        background: rgba(224, 224, 224, 0.8);
        color: #4E4E4E;
        border-color: #BCBCBC;
    }

    .diceHistoryEntry.red .Icon.arrow-right {
        stroke: #FFDFDE;
    }

    .diceHistoryEntry.blue .Icon.arrow-right {
        stroke: #457386;
    }

    .diceHistoryEntry.green .Icon.arrow-right {
        stroke: #EAFFE8;
    }

    .diceHistoryEntry.yellow .Icon.arrow-right {
        stroke: #a45d00;
    }

    .diceHistoryEntry.lilac .Icon.arrow-right {
        stroke: #5e00a3;
    }

    .diceHistoryEntry.orange .Icon.arrow-right {
        stroke: #7e4900;
    }

    .diceHistoryEntry.black .Icon.arrow-right {
        stroke: #BABABA;
    }

    .diceHistoryEntry.white .Icon.arrow-right {
        stroke: #4E4E4E;
    }

    .diceHistoryEntry.red .totals {
        background: #E69E9D;
    }

    .diceHistoryEntry.blue .totals {
        background: #7FAABC;
    }

    .diceHistoryEntry.green .totals {
        background: #9BD095;
    }

    .diceHistoryEntry.yellow .totals {
        background: #F7DCB9;
    }

    .diceHistoryEntry.lilac .totals {
        background: #d396ff;
    }

    .diceHistoryEntry.orange .totals {
        background: #ffb95a;
    }

    .diceHistoryEntry.black .totals {
        background: #656565;
    }

    .diceHistoryEntry.white .totals {
        background: #e0e0e0;
    }

    .force-light-mode .diceHistoryEntry.red {
        background: rgba(255, 223, 222, 0.8);
        color: #BF4747;
        border-color: #E69E9D;
    }

    .force-light-mode .diceHistoryEntry.blue {
        background: rgba(242, 249, 253, 0.8);
        color: #457386;
        border-color: #D7EBF7;
    }

    .force-light-mode .diceHistoryEntry.green {
        background: rgba(234, 255, 232, 0.8);
        color: #4E9347;
        border-color: #9BD095;
    }

    .force-light-mode .diceHistoryEntry.yellow {
        background: rgba(255, 249, 242, 0.8);
        color: #a45d00;
        border-color: #F7DCB9;
    }

    .force-light-mode .diceHistoryEntry.lilac {
        background: rgba(211, 150, 255, 0.8);
        color: #5e00a3;
        border-color: #C06AFF;
    }

    .force-light-mode .diceHistoryEntry.orange {
        background: rgba(255, 185, 90, 0.8);
        color: #7e4900;
        border-color: #EB9C30;
    }

    .force-light-mode .diceHistoryEntry.black {
        background: rgba(37, 36, 36, 0.8);
        color: #BABABA;
        border-color: #656565;
    }

    .force-light-mode .diceHistoryEntry.white {
        background: rgba(224, 224, 224, 0.8);
        color: #4E4E4E;
        border-color: #BCBCBC;
    }

    .force-light-mode .diceHistoryEntry.red .totals {
        background: #E69E9D;
    }

    .force-light-mode .diceHistoryEntry.red.failure .totals {
        background: none;
        border: 3px solid #E69E9D
    }

    .force-light-mode .diceHistoryEntry.blue .totals {
        background: #D7EBF7;
    }

    .force-light-mode .diceHistoryEntry.blue.failure .totals {
        background: none;
        border: 3px solid #D7EBF7;
    }

    .force-light-mode .diceHistoryEntry.green .totals {
        background: #9BD095;
    }

    .force-light-mode .diceHistoryEntry.green.failure .totals {
        background: none;
        border: 3px solid #9BD095;
    }

    .force-light-mode .diceHistoryEntry.yellow .totals {
        background: #F7DCB9;
    }

    .force-light-mode .diceHistoryEntry.yellow.failure .totals {
        background: none;
        border: 3px solid #F7DCB9;
    }

    .force-light-mode .diceHistoryEntry.lilac .totals {
        background: #d396ff;
    }

    .force-light-mode .diceHistoryEntry.stone.failure .totals {
        background: none;
        border: 3px solid #404d55;
    }

    .force-light-mode .diceHistoryEntry.orange .totals {
        background: #ffb95a;
    }

    .force-light-mode .diceHistoryEntry.lilac.failure .totals {
        background: none;
        border: 3px solid #C06AFF;
    }

    .force-light-mode .diceHistoryEntry.black .totals {
        background: #656565;
    }

    .force-light-mode .diceHistoryEntry.orange.failure .totals {
        background: none;
        border: 3px solid #ffb95a;
    }

    .force-light-mode .diceHistoryEntry.white .totals {
        background: #e0e0e0;
    }

    .force-light-mode .diceHistoryEntry.black.failure .totals {
        background: none;
        border: 3px solid #656565
    }

    .force-light-mode .diceHistoryEntry.red .explosion {
        background: #E69E9D;
    }

    .force-light-mode .diceHistoryEntry.blue .explosion {
        background: #D7EBF7;
    }

    .force-light-mode .diceHistoryEntry.green .explosion {
        background: #9BD095;
    }

    .force-light-mode .diceHistoryEntry.yellow .explosion {
        background: #F7DCB9;
    }

    .force-light-mode .diceHistoryEntry.lilac .explosion {
        background: #d396ff;
    }

    .force-light-mode .diceHistoryEntry.orange .explosion {
        background: #ffb95a;
    }

    .force-light-mode .diceHistoryEntry.black .explosion {
        background: #656565;
    }

    .force-light-mode .diceHistoryEntry.white .explosion {
        background: #BCBCBC;
    }

    .force-light-mode .diceHistoryEntry.red .Icon.arrow-right {
        stroke: #BF4747;
    }

    .force-light-mode .diceHistoryEntry.blue .Icon.arrow-right {
        stroke: #457386;
    }

    .force-light-mode .diceHistoryEntry.green .Icon.arrow-right {
        stroke: #4E9347;
    }

    .force-light-mode .diceHistoryEntry.yellow .Icon.arrow-right {
        stroke: #a45d00;
    }

    .force-light-mode .diceHistoryEntry.lilac .Icon.arrow-right {
        stroke: #5e00a3;
    }

    .force-light-mode .diceHistoryEntry.orange .Icon.arrow-right {
        stroke: #7e4900;
    }

    .force-light-mode .diceHistoryEntry.black .Icon.arrow-right {
        stroke: #BABABA;
    }

    .force-light-mode .diceHistoryEntry.white .Icon.arrow-right {
        stroke: #4E4E4E;
    }
}