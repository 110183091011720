.mapTokensWrapper .measure {
    position: absolute;
    background-color: #f5f9fa;
    border-radius: 8px;
    border: 2px solid #465058;
    transform-origin: 3px 3px;

    width: 0px;
    height: 6px;

    z-index: 10000;
    pointer-events: none;
}

.measureDisplay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    height: 32px;
    min-width: 32px;

    top: -16px;
    border-radius: 8px;
    padding: 0.5rem;

    white-space: nowrap;
}