.MapTokens .grid.adjusting {
    pointer-events: all;
    transition: none;
}

.gridScaler {
    border: 1px dashed;
    position: absolute;
    z-index: 10000000;
    pointer-events: all;
}

.gridScaler p {
    position: absolute;
}

.gridScaler .topLeft {
    position: absolute;
    top: 0;
    left: 0;

    border-right: none;
    border-bottom: none;
    cursor: move;
}


.gridScaler .bottomRight {
    position: absolute;
    bottom: 0;
    right: 0;

    border-top: none;
    border-left: none;

    cursor: nwse-resize;
}