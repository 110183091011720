* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  cursor: pointer;
}

html {
  scroll-behavior: smooth;
}


::-webkit-scrollbar {
  width: 11px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  height: 3px;
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  border-radius: 7px;
  background-color: #ECEFF3;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #CCD5DB;
}

/* Forced Dark mode */

.force-dark-mode.App {background-color: #21262c;}

.force-dark-mode ::-webkit-scrollbar-thumb {background-color: #465058;}
.force-dark-mode ::placeholder {color: #abb6bf;}

/* Dark Mode */
@media (prefers-color-scheme: dark) {
  .App {background-color: #21262c;}
  ::-webkit-scrollbar-thumb {background-color: #465058;}
  ::placeholder {color: #abb6bf;}

    /* Forced Light theme in dark mode */
    .force-light-mode.App {background-color: #FBFBFB;}
    .force-light-mode ::-webkit-scrollbar-thumb {background-color: #ECEFF3;}
    .force-light-mode ::placeholder {color: #CCD5DB;}
}