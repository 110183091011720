
.chest-inner-user-count-button {
    background: none;
    border: none;
    outline: none;
}

.chest-inner-user-count {
    color: #647682;
    font-size: 12px;
}

.chest-inner-user-count.red {
    color: #E17E7E;
}

.chest-inner-user-count-message {
    font-size: 10px;
    color: #B2BDC5;
}

.chest-inner-user-count-button:hover .chest-inner-user-count, .chest-inner-user-count-button:hover .chest-inner-user-count-message {
    color: #465058;
}