.c-ice {
    color: #f2f9fd;
}

.c-light-blue {
    color: #b4e1e9;
}

.c-blue {
    color: #6EADC6;
}

.c-dark-blue {
    color: #7faabc;
}

.c-midnight-blue {
    color: #404d55;
}

.c-red {
    color: #e17e7e;
}

.c-light-red {
    color: #EC9898;
}

.c-dark-red {
    color: #DF5353;
}

.c-green {
    color: #9bd095;
}

.c-light-green {
    color: #BCE0B8;
}

.c-dark-green {
    color: #87C77F;
}

.c-yellow {
    color: #ffedd6;
}

.c-light-yellow {
    color: #FFF6EB;
}

.c-dark-yellow {
    color: #FFDCAD;
}

.c-cyan {
    color: #b4e1e9;
}

.c-light-cyan {
    color: #f2f9fd;
}

.c-dark-cyan {
    color: #90D3DF;
}

.c-purple {
    color: #B18CD9;
}

.c-pink {
    color: #FFC8E5;
}

.c-orange {
    color: #FFB864;
}

.c-white {
    color: #FBFBFB;
}

.c-shade-1 {
    color: #f5f9fa;
}

.c-shade-2 {
    color: #eceff3;
}

.c-shade-3 {
    color: #dee1e8;
}

.c-shade-4 {
    color: #ccd5db;
}

.c-shade-5 {
    color: #abb6bf;
}

.c-shade-6,
.c-main {
    color: #647682;
}

.c-shade-7 {
    color: #465058;
}

.c-shade-8 {
    color: #333c43;
}

.c-shade-9 {
    color: #21262c;
}

.c-black {
    color: #333;
}

.fill-main path {
    fill: #647682;
}


/* Forced Dark Mode */
.force-dark-mode .c-white {
    color: #21262c;
}

.force-dark-mode .c-shade-1 {
    color: #333c43;
}

.force-dark-mode .c-shade-2 {
    color: #465058;
}

.force-dark-mode .c-shade-3,
.force-dark-mode .c-main {
    color: #647682;
}

.force-dark-mode .c-shade-4 {
    color: #abb6bf;
}

.force-dark-mode .c-shade-5 {
    color: #ccd5db;
}

.force-dark-mode .c-shade-6 {
    color: #dee1e8;
}

.force-dark-mode .c-shade-7 {
    color: #eceff3;
}

.force-dark-mode .c-shade-8 {
    color: #f5f9fa;
}

.force-dark-mode .c-shade-9 {
    color: #FBFBFB;
}

.force-dark-mode .c-black {
    color: #333;
}

.force-dark-mode .c-red {
    color: #e17e7e;
}


/* Dark Mode */
@media (prefers-color-scheme: dark) {
    .c-white {
        color: #21262c;
    }

    .c-shade-1 {
        color: #333c43;
    }

    .c-shade-2 {
        color: #465058;
    }

    .c-shade-3,
    .c-main {
        color: #647682;
    }

    .c-shade-4 {
        color: #abb6bf;
    }

    .c-shade-5 {
        color: #ccd5db;
    }

    .c-shade-6 {
        color: #dee1e8;
    }

    .c-shade-7 {
        color: #eceff3;
    }

    .c-shade-8 {
        color: #f5f9fa;
    }

    .c-shade-9 {
        color: #FBFBFB;
    }

    .c-black {
        color: #333;
    }

    .c-red {
        color: #e17e7e;
    }

    /* Forced Light theme in dark mode */
    .force-light-mode .c-white {
        color: #FBFBFB;
    }

    .force-light-mode .c-shade-1 {
        color: #f5f9fa;
    }

    .force-light-mode .c-shade-2 {
        color: #eceff3;
    }

    .force-light-mode .c-shade-3,
    .force-light-mode .c-main {
        color: #647682;
    }

    .force-light-mode .c-shade-4 {
        color: #ccd5db;
    }

    .force-light-mode .c-shade-5 {
        color: #abb6bf;
    }

    .force-light-mode .c-shade-6 {
        color: #647682;
    }

    .force-light-mode .c-shade-7 {
        color: #465058;
    }

    .force-light-mode .c-shade-8 {
        color: #333c43;
    }

    .force-light-mode .c-shade-9 {
        color: #21262c;
    }

    .force-light-mode .c-black {
        color: #333;
    }

    .force-light-mode .c-red {
        color: #e17e7e;
    }
}