.price-display {
    color: #647682;
    font-size: 2rem;
    font-weight: bold;
}

.price-display span {
    font-size: 1rem;
    color: #ABB6BF;
}

.price-display.green {
    color: #46916E;
}

.price-display.green span {
    color: #49A579;
}