.auth {
    min-height: 100vh;
    width: 100%;
    background-image: url("../../assets/mountain-background.svg"), linear-gradient(to top, rgb(46, 118, 155) 0%, rgba(156, 197, 234) 70%, rgba(138, 179, 215) 100%);
    background-size: cover;
    display: flex;
}

.auth-logo-container {
    flex-grow: 1;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    text-align: center;
}

.auth-logo-container h1 {
    font-size: 3rem;
    color: white;
    font-family: 'Poppins', sans-serif;
    text-transform: none;
    font-weight: bold;
    text-shadow: 3px 3px 0 rgba(0, 0, 0, 0.15);
}

.auth-logo {
    width: 50%;
    max-width: 512px;
}

.mobile-logo{
    display: none;
    width: 215px;
    margin-top: 3rem;
    fill: #647682;
}

.auth-menu-container {
    position: relative;
    display: flex;
    flex-direction: column;
    background: #ffffffd1;
    width:400px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 400px;
}

/* Forced Dark Mode */
.force-dark-mode .auth-menu-container {background: #272727d1;}
.force-dark-mode .auth {background-image: url("../../assets/mountain-background-dark.svg"), linear-gradient(to top, rgb(39, 100, 130) 0%, rgba(39, 100, 130) 70%, rgba(31, 80, 110) 100%);}
/* Dark Mode */
@media (prefers-color-scheme: dark) {
    .auth-menu-container {background: #272727d1;}
    .auth {background-image: url("../../assets/mountain-background-dark.svg"), linear-gradient(to top, rgb(39, 100, 130) 0%, rgba(39, 100, 130) 70%, rgba(31, 80, 110) 100%);}
    /* Forced Light theme in dark mode */
    .force-light-mode .auth-menu-container {background: #ffffffd1;}
    .force-light-mode .auth {background-image: url("../../assets/mountain-background.svg"), linear-gradient(to top, rgb(46, 118, 155) 0%, rgba(156, 197, 234) 70%, rgba(138, 179, 215) 100%);}
}

.auth-inner-container, .auth-login-container, .auth-signup-container {
    width: 100%;
}

.auth-inner-container {
    padding: 1rem;
    text-align: center;
}

.auth-textInput {
    width: 100%;
    margin-bottom: 2rem;
}

.auth-login-button, .auth-signup-button {
    margin-bottom: 1rem;
}

.auth-signup-toggle, .auth-login-toggle {
    display: block;
    margin: 0 auto;
}

.password-reset-success .Icon {
    width: 24px; 
    height: 24px;
    stroke: #87C77F;
    stroke-width: 3px;
}

.checkbox.newsletter-signup {
    background: transparent;
    font-size: 0.75rem;
}

@media only screen and (max-width: 600px) {
    .auth-logo-container{ display: none;}
    .auth-menu-container { flex: 1 1; }
    .mobile-logo {display: block;}
}