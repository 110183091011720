.mapModal {
    position: absolute;
    width: 278px;
    height: 100%;
    top: 0;
    left: -278px;
    overflow: visible;
    z-index: 13;
    transition: transform 0.2s ease-out;
    pointer-events: all;
}

.tab .mapModal {
    top: 38px;
}

.mapModal.left {
    left: -278px;
    right: auto;
    border-right: 3px solid #ACDCE5;
}

.mapModal.left.open {
    transform: translateX(278px);
}

.mapModal.right {
    right: -278px;
    left: auto;
    border-left: 3px solid #ACDCE5;
}

.mapModal.right.open {
    transform: translateX(-278px);
}

.tab .tokenListModal {
    margin-top: 3rem;
}

.tokenList-header-button svg {
    stroke: none;
}

.drawSettings .iconButton.selected,
.drawSettings .iconButton.selected:hover {
    color: #b4e1e9;
    background: #647682;
}

.drawSettings .iconButton svg {
    stroke: none;
}

.usersTokenListItem {
    position: relative;
}

.usersTokenListItem:hover .tokenDeleteButton {
    transform: scale(1);
    transition: transform 0.1s ease-out 0.3s;
}

.tokenDeleteButton {
    position: absolute;
    top: 0;
    right: 0;
    width: 16px;
    height: 16px;
    padding: 4px;
    border: none;
    transform: scale(0);
    transition: transform 0.1s ease-out;
}

.tokenDeleteButton:hover {
    transform: scale(1.3);
}

.tokenDeleteButton svg {
    stroke: #ca6565;
    stroke-width: 3px;
}

.tab .pingSettings {
    margin-top: 3rem;
}

.tab .drawSettings {
    margin-top: 3rem;
}

.tokenPropertiesCrown {
    position: absolute;
    left: 40px;
    top: 10px;
    padding: 3px;
}