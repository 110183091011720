.chest {
    content: '';
    position: absolute;
    top: -64px;
    left: -64px;
    border-radius: 100%;
    box-shadow: 3px 3px 0 rgba(51, 60, 67, 0.15);
    width: 138px;
    flex-basis: 0;
    height: 138px;
    z-index: 10;
    transition: all 0.3s ease-out, left 0.5s ease-out;
}

.chest.open {
    position: relative;
    height: 100%;
    width: 100%;
    flex-basis: 100%;
    border-radius: 0;
    left: 0px;
    top: 0px;
    border-right: none;
}

@media only screen and (min-width: 769px) {
    .chest.open {
        width: 375px;
        flex-basis: 375px;
    }
}

.force-dark-mode .chest.open {
    background-color: #21262c;
}

/* Dark Mode */
@media (prefers-color-scheme: dark) {
    .chest.open {
        background-color: #21262c;
    }

    /* Forced Light theme in dark mode */
    .force-light-mode .chest.open {
        background-color: #FBFBFB;
    }
}

.chest-inner {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    opacity: 0;
    animation: fade-in 0.3s ease-out 0.5s forwards;
    z-index: -10;
}

.chest-header {
    height: 135px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.chest-tabs-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    border-top: 2px solids;
    flex-grow: 1;
    text-align: center;
    overflow-y: auto;
}

.chest-inner-content {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100%;
}

.chest-title {
    text-transform: uppercase;
    font-weight: bold;
    width: 100%;
    margin: 0;
    max-width: 100%;
    padding-left: 64px;
    box-sizing: border-box;
    z-index: -10;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.2rem;
    display: block;
    white-space: nowrap;
}

.chest-subtitle-section {
    margin-left: 4rem;
}

.chest-subtitle-button {
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    padding: 0;
}

@keyframes fade-in {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.chest-header {
    padding: 1rem;
}

.chest-footer-section {
    width: 100%;
    height: 48px;
    min-height: 48px;
    display: flex;
    align-items: center;
    border-top: 2px solid #EBEBEB;
    justify-content: space-between;
}

.logoutButton {
    font-size: 0.75rem;
}

.chest-list-item-row {
    display: flex;
    align-items: center;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.chest-list-item-col {
    display: flex;
    flex-direction: column;
}

.chest-list-item h3 {
    margin: 0;
    margin-right: 1rem;
    font-size: 1rem;
    text-transform: capitalize;
}

.chest-list-item p {
    margin: 0;
    font-size: 0.75rem;
    color: #647682;
}

.chest-list-item span {
    display: flex;
    font-size: 0.75rem;
    text-transform: capitalize;
    color: #6e6e6e;
}

.chest-list-item span .Icon {
    margin-left: 0.25rem;
    margin-right: 0.1rem;
}

.chest-list-icon {
    height: 32px;
}