.mapTokensWrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    opacity: 0;
    transform: translate(0px, 0px) scale(1);
    transition: transform 1s cubic-bezier(.63, .31, .42, 1.24), opacity 1s ease-in;
}

.gridOffsetContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.3s cubic-bezier(.63, .31, .42, 1.24), opacity ease-in;
}

.mapTokensWrapper.bgLoaded {
    opacity: 1;
    transform: translate(0px, 0px) scale(1);
}

.mapTokensWrapper.noBG {
    opacity: 0;
    animation: fade-in 1s cubic-bezier(.63, .31, .42, 1.24) forwards;
}


@keyframes fade-in {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

.MapTokens {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top left;
    user-select: none;
    touch-action: none;
    pointer-events: none;

    /* border: 3px solid #21262c; */
    /* outline: 3px solid #21262c; */
    box-sizing: content-box;
}

.MapTokens.addPan {
    transition: all 0.6s cubic-bezier(.63, .31, .42, 1.24);
}

.MapTokens.addPan+.fog-renderer {
    transition: all 0.6s cubic-bezier(.63, .31, .42, 1.24);
}

.MapTokens .grid {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;

    background: -webkit-linear-gradient(to right, currentColor 1px, transparent 1px), -webkit-linear-gradient(to bottom, currentColor 1px, transparent 1px);
    background: -moz-linear-gradient(to right, currentColor 1px, transparent 1px), -moz-linear-gradient(to bottom, currentColor 1px, transparent 1px);
    background: -o-linear-gradient(to right, currentColor 1px, transparent 1px), linear-gradient(to bottom, currentColor 1px, transparent 1px);
    background: linear-gradient(to right, currentColor 1px, transparent 1px), linear-gradient(to bottom, currentColor 1px, transparent 1px);

    transition: all 0.3s cubic-bezier(.63, .31, .42, 1.24);
}

.MapTokens .token {
    position: absolute;
    width: 32px;
    height: 32px;
    background-size: cover;
    user-select: none;

    transition: all 0.3s cubic-bezier(.63, .31, .42, 1.24);

    border-radius: 100%;
    z-index: 0;
}

.MapTokens .token.dragging,
.aura-container.dragging {
    transition: none;
}

.MapTokens .token.selected {
    z-index: 10000;
}

.MapTokens .token.hovered .name,
.MapTokens .token .name.showNames {
    transform: translate(-50%) scale(1);
    user-select: none;
}

.MapTokens .token .name {
    position: absolute;
    text-align: center;
    bottom: -12px;
    width: auto;
    overflow: hidden;
    left: 50%;
    transform: translate(-50%);
    border-radius: 8px;
    /* background: rgba(255, 255, 255, 0.8); */
    opacity: 0.8;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 2px 0.25rem;
    line-height: 1;

    transform: translate(-50%) scale(0);

    transition: transform 0.1s ease-out;

    z-index: 2;
    pointer-events: none;
}

.MapTokens .token .ring {
    position: absolute;
    pointer-events: none;
    border-radius: 100%;
    border-width: 0px 0px 0px 0px;
    border-style: solid;
    transition: border-width 0.1s ease-in-out, width 0.1s ease-in-out, height 0.1s ease-in-out, top 0.1s ease-in-out, left 0.1s ease-in-out;
    animation: rotate 3s ease-in-out alternate infinite;
    pointer-events: none;
}

.aura-container {
    position: absolute;
    transition: top 0.3s cubic-bezier(.63, .31, .42, 1.24), left 0.3s cubic-bezier(.63, .31, .42, 1.24), width 0.3s cubic-bezier(.63, .31, .42, 1.24), height 0.3s cubic-bezier(.63, .31, .42, 1.24);
}

.gridOffsetContainer .aura {
    z-index: -1;
    position: absolute;
    pointer-events: none;
    border-radius: 100%;
    opacity: 0.5;
    transition: top 0.3s cubic-bezier(.63, .31, .42, 1.24), left 0.3s cubic-bezier(.63, .31, .42, 1.24), width 0.3s cubic-bezier(.63, .31, .42, 1.24), height 0.3s cubic-bezier(.63, .31, .42, 1.24);
}

.MapTokens .token.animating {
    transition: top 0.3s ease-in, left 0.3s ease-in, opacity 0.3s ease-in;
}

.MapTokens .token.selectable {
    pointer-events: all;
}

.MapTokens .token.hovered:after {
    /* display: block; */
    top: -3%;
    left: -3%;
    width: 106%;
    height: 106%;
    opacity: 1;
}

.MapTokens .token.selected:after {
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    opacity: 1;
    z-index: 1;

    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100%' ry='100%' stroke='rgb(155 208 149)' stroke-width='12%' stroke-dasharray='50%' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
}

.MapTokens .token:after {
    content: '';
    /* display: none; */
    position: absolute;
    pointer-events: none;
    width: 150%;
    height: 150%;
    top: -25%;
    left: -25%;
    border-radius: 100%;
    opacity: 0;
    z-index: 1;

    transition: all 0.1s ease-out;

    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100%' ry='100%' stroke='white' stroke-width='6%' stroke-dasharray='25%' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");

    animation: rotate 5s linear infinite;
}

.MapTokens .token.selected.locked:after {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100%' ry='100%' stroke='rgb(225, 126, 126)' stroke-width='12%' stroke-dasharray='50%' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
}

.token .tokenBackground {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    transition: transform 0.3s cubic-bezier(.63, .31, .42, 1.24);
    pointer-events: none;
}

.token.layer-0 {
    z-index: 0;
}

.token.layer-1 {
    z-index: 1;
}

.token.layer-2 {
    z-index: 2;
}

.token.layer-3 {
    z-index: 3;
}

.token.layer-4 {
    z-index: 4;
}

.token.layer-5 {
    z-index: 5;
}

.token.layer-6 {
    z-index: 6;
}

.documentIcon {
    position: absolute;
    display: flex;
    pointer-events: none;
    z-index: 100;
    transition: all 0.3s cubic-bezier(.63, .31, .42, 1.24);
}

.docPreviewList {
    display: none;
    width: 150px;
    height: 164px;
    overflow: auto;
    position: absolute;
    top: 12px;
    left: 32px;
    padding: 0 0.25rem;
    transform-origin: top left;
    pointer-events: all;
    z-index: 100;
    transition: transform 0.3s cubic-bezier(.63, .31, .42, 1.24), opacity 1s ease-in;
}

.docPreviewList.hovered {
    display: block;
    animation: fade-in 0.3s cubic-bezier(.63, .31, .42, 1.24);
    opacity: 1;
}

.dragging .docPreviewList {
    display: none;
}

@keyframes grow-in {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.docPreview {
    width: 100%;
    height: calc(100% - 0.5rem);
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    overflow: hidden;
    z-index: 100000;
}

@keyframes rotate {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes growRing {
    from {
        border-width: 0px 0px 0px 0px;
    }

    to {
        border-width: 3px 3px 3px 3px;
    }
}

@keyframes drop-in {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}