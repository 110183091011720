@keyframes add-button-slide-in {
    from{right: -64px}
    to{right: 16px}
}

.AddItems {
    display: none;
    position: absolute;
    bottom: 16px;
    right: -64px;
    animation: add-button-slide-in 0.3s ease-out forwards;
    z-index: 100000000;
}

.AddItems .Icon.plus svg {
    stroke: #647682;
}

.AddItems__sub {
    position: absolute;
    bottom: 3px;
    left: 1px;
    z-index: -10;
    width: 48px;
    height: 48px;
    font-size: 24px;
}

.AddItems {bottom: 16px; right: 64px;}

.AddItems.open .new-character-button {bottom: 82px;}
.AddItems.open .new-map-button {bottom: 164px;}
.AddItems.open .new-note-button {bottom: 246px;}

.tabs-container {
    position: absolute;
    bottom: 0;
    right: 64px;
}

.tab-button {
    position: relative;
    bottom:0;
    width: 32px;
    height: 32px;
    border-radius: 32px;
    transition: width 0.2s ease-out, transform 0.2s ease-out, background-color 0.2 ease-out;
    animation: pop-in 0.3s ease-out;
}

.tab-button.selected {
    background: none;
    transform: scale(1.1);
}

.minimizeItem {
    transition: transform 0.2s ease-out;
    animation: pop-in 0.1s ease-out;
}

@keyframes pop-in {
    from{transform: scale(0);}
    to{transform: scale(1);}
}

.tab-button-p {
    margin: 0;
    transform: scaleX(0);
    transition: transform 0.2s ease-out;
}

.tab-button-wrapper:hover .tab-button {
    width: 96px;
    height: 32px;
}

.tab-button-wrapper:hover .tab-button-p {
    margin-left: 0.5rem;
    transform: scaleX(1);
}

.AddItems__button.selectedTabIsMap {
    box-shadow: 0px 0px 0 black;
}

.AddItems__button.selectedTabIsMap:hover {
    box-shadow: 0px 0px 0 rgb(25 29 32 / 60%);
}

.tab-broadcast-icon {
    position: absolute;
    top: -8px;
    left: -8px;
    width: 20px;
    height: 20px;
    padding: 4px;
    stroke: none;
    display: flex;
}

/* Medium devices (landscape tablets, 769px and up) */
@media only screen and (min-width: 769px) {
    .AddItems {
        display: block;
    }
}

.hoveredPreview {
    position: absolute;
    bottom: 3rem;
}