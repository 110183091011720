.noteEditor > .ql-toolbar {
    height: 38px;
    border: none;
    border-bottom: 1px solid #647682;
}

.noteEditor .ql-picker {color: #647682;}
.noteEditor .ql-stroke {stroke: #647682;}
.noteEditor .ql-fill {fill: #647682;}

.noteEditor > .ql-container {
    border: none;
}

.tab .noteEditor  .ql-container {
    height: calc(100% - 38px);
    margin-top: 38px;
}

.tab .documentListItem--body .noteEditor  .ql-container {
    height: 100%;
}

.tab .noteEditor .ql-editor {
    padding-top: 3rem;
    padding-left: 3rem;
    padding-right: 3rem;
}

.documentListItem--body .ql-editor, .tab .documentListItem--body .ql-editor {
    padding: 1rem;
}

.documentListItem--body .ql-container, .tab .documentListItem--body .ql-container {
    margin-top: 0;
}

.ql-active {
    border-bottom: 2px solid #647682;
}

.noteEditor .ql-editor.ql-blank::before {
    color: #abb6bf;
}


/* Forced Dark Mode */
.force-dark-mode .noteEditor > .ql-toolbar {
    border-bottom: 1px solid #647682;
}


.force-dark-mode .noteEditor .ql-editor.ql-blank::before {
    color: #647682;
}

/* Dark Mode */
@media (prefers-color-scheme: dark) {    

    .noteEditor .ql-editor.ql-blank::before {
        color: #647682;
    }

    .force-light-mode .noteEditor > .ql-toolbar {
        border-bottom: 1px solid #647682;
    }

    .force-light-mode .noteEditor .ql-editor.ql-blank::before {
        color: #abb6bf;
    }
}