.border-none {
    border: none;
}

.border-thick {
    border: 3px solid;
    border-color: #ABB6BF;
}

.border {
    border: 2px solid;
    border-color: #ABB6BF;
}

.border-thin {
    border: 1px solid;
    border-color: #ABB6BF;
}

.border-left-thick {
    border: none;
    border-left: 3px solid;
    border-color: #ABB6BF;
}

.border-left {
    border: none;
    border-left: 2px solid;
    border-color: #ABB6BF;
}

.border-left-thin {
    border: none;
    border-left: 1px solid;
    border-color: #ABB6BF;
}

.border-right-thick {
    border: none;
    border-right: 3px solid;
    border-color: #ABB6BF;
}

.border-right {
    border: none;
    border-right: 2px solid;
    border-color: #ABB6BF;
}

.border-right-thin {
    border: none;
    border-right: 1px solid;
    border-color: #ABB6BF;
}

.border-top-thick {
    border: none;
    border-top: 3px solid;
    border-color: #ABB6BF;
}

.border-top {
    border: none;
    border-top: 2px solid;
    border-color: #ABB6BF;
}

.border-top-thin {
    border: none;
    border-top: 1px solid;
    border-color: #ABB6BF;
}

.border-bottom-thick {
    border: none;
    border-bottom: 3px solid;
    border-color: #ABB6BF;
}

.border-bottom {
    border: none;
    border-bottom: 2px solid;
    border-color: #ABB6BF;
}

.border-bottom-thin {
    border: none;
    border-bottom: 1px solid;
    border-color: #ABB6BF;
}

.rounded-0, .square {
    border-radius: 0;
}

.rounded-quarter {
    border-radius: 2px;
}

.rounded-half {
    border-radius: 4px;
}

.rounded, .rounded-1 {
    border-radius: 8px;
}

.rounded-2 {
    border-radius: 12px;
}

.round {
    border-radius: 100%;
}

.disabled {
    opacity: 0.3;
}

.disabled, .no-pointer-events {
    pointer-events: none;
}

input:disabled {
    opacity: 0.3;
}

.inline-flex {
    display: inline-flex;
}

.cursor-pointer {
    cursor: pointer;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.flex-1 {
    flex: 1 1;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-y-auto {
    overflow-y: auto;
}

.overflow-y-scroll {
    overflow-y: scroll;
}

.flip-y {
    transform: rotate(180deg);
}

.fade-in {
    opacity: 0;
    animation: fade-in 0.3s ease-out forwards;
}

.stroke-none {
    stroke: none;
}

.relative {
    position: relative;
}

.grow-hover {
    transition: transform 0.1s ease-out;
}

.grow-hover:hover {
    transform: scale(1.1);
}

.opacity-half {
    opacity: 0.5;
}

.opacity-75 {
    opacity: 0.75;
}

.position-absolute {
    position: absolute;
}

@keyframes fade-in {
    from{opacity: 0;}
    to{opacity:1;}
}

@keyframes shake {
    0%{ transform: rotate(0deg) scale(1);}
    25%{ transform: rotate(10deg) scale(1); }
    50%{ transform: rotate(0deg) scale(1.1); }
    75%{ transform: rotate(-10deg) scale(1.1); }
    100%{ transform: rotate(0deg) scale(1.1); }
}

@keyframes shake-upside-down {
    0%{ transform: rotate(180deg) scale(1);}
    25%{ transform: rotate(190deg) scale(1); }
    50%{ transform: rotate(180deg) scale(1.1); }
    75%{ transform: rotate(170deg) scale(1.1); }
    100%{ transform: rotate(180deg) scale(1.1); }
}