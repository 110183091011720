.drawings-container {
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.drawings-container .drawing {
    pointer-events: none;
}

.drawings-container.selectable .drawing {
    pointer-events: all;
}

.drawing {
    position: absolute;
    pointer-events: all;
    cursor: grab;
    opacity: 0.8;
    outline: 0px solid rgba(255, 255, 255, 0);
    transition: all 0.3s cubic-bezier(.63, .31, .42, 1.24);
}

.drawing.current-drawing,
.drawing.dragging {
    transition: none;
}

.drawing .white-drawing {
    outline: 0px solid rgb(155, 208, 149, 0);
}

.measureDisplay {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    height: 32px;
    min-width: 32px;

    top: -16px;
    border-radius: 8px;
    padding: 0.5rem;

    white-space: nowrap;
}

.drawing-controls {
    position: absolute;
    top: -1rem;
    right: -1rem;

    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-out;
}

.drawing.circle .drawing-controls {
    right: calc(50% - 18px);
}

.drawing:hover {
    outline: 3px solid rgba(255, 255, 255, 1);
}

.drawing:hover .white-drawing {
    outline: 3px solid rgb(155, 208, 149, 1);
}

.drawing:hover .drawing-controls {
    opacity: 1;
    pointer-events: all;
}

.current-drawing .drawing-controls {
    display: none;
}

.drawing.diceInHand {
    outline: 3px solid rgba(255, 255, 255, 0);
    pointer-events: none;
}

.drawing.diceInHand .drawing-controls {
    opacity: 0;
    pointer-events: none;
}

.current-marker-drawing {
    pointer-events: all;
    width: 100%;
    height: 100%;
}

.marker-drawing {
    width: 100%;
    height: 100%;
}