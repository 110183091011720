.copyTextInput-container{
    position: relative;
    width: 100%;
}

.copyTextInput {
     width: 100%;
    cursor: pointer;
}

.copyTextInput .textInput {
    cursor: pointer;
}

.invite-link-small {
    padding: 4px 8px;
    box-shadow: none;
    transform: none;
    background: #e7e7e7;
    border: none;
    user-select: none;
}

.invite-link-small:hover {
    background-color: #dbdbdb;
    cursor: pointer;
}

.invite-link-small:active, .invite-link-small:focus {
    box-shadow: none;
    transform: none;
}

.copied-tooltip {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 0.75rem;
    background-color: #647682;
    color: white;
    padding: 4px;
    border-radius: 12px;

    cursor: pointer;
    user-select: none;

    animation: fade-in 0.1s ease-out;
}

@keyframes fade-in {
    from{
        opacity: 0;
    }

    to{
        opacity: 1;
    }
}