.c-shade-5-hover:hover {
    color: #647682;
}

.c-dark-blue-hover:hover {
    color: #7faabc;
}

/* Forced Dark Mode */
.force-dark-mode .c-shade-5-hover:hover {
    color: #dee1e8;
}

/* Dark Mode */
@media (prefers-color-scheme: dark) {
    .c-shade-5-hover:hover {
        color: #dee1e8;
    }

    /* Forced Light theme in dark mode */
    .force-light-mode .c-shade-5-hover:hover {
        color: #647682;
    }
}