.landing-header{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0 1.5rem;
    display: flex;
    justify-content: flex-end;

    color: white;
    z-index: 3;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    font-size: 1rem;
}

.landing-header .iconButton {
    padding: 0.75rem;
    padding-right: 0;
    padding-left: 1rem;
    margin-top: 0.5rem
}

.landing-header ul {
    list-style: none;
    display: flex;
    padding: 0;
}

.landing-header li {
    padding-right: 1rem;
}

.landing-header nav {
    display: flex;
    justify-content: space-between;
}

.landing-header a {
    text-decoration: none;
    display: block;
}

.landing-header a:hover, .landing-header a:focus {
    transform: scale(1.1);
}

.landing-header a:active {
    transform: scale(0.9);
}

.landing-header a:visited {
    text-decoration: none;
}

.landing-header .left a {
    color: #647682;
}

.landing-header .left a:hover, .landing-header .left a:focus {
    color: #647682;
}

.landing-header .left a:visited {
    color: #647682;
}

.mobile-nav {
    display:block;
}

.nav {
    display:none;
    width: 100%;
}

.nav-hamburger img {
    width: 24px;
    height: 24px;
}

.mobile-nav-menu {
    position: fixed;
    top: 0;
    left: 100%;
    width: 100%;
    height: 100vh;
    transition: left 0.3s ease-out;
    background-color: white;
    color: #647682;
    display: flex;
    flex-direction: column;

    transition: left 0.3s ease-out, right 0.3s ease-out;
}

.desktop-nav-menu nav {
    background-color: none;
}

.mobile-nav-menu nav {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    background-color: white;
    height: 100%;
}

.mobile-nav-menu .right {
    flex-direction: column-reverse;
}

.mobile-nav-header {
    display: flex;
    align-items: center;
    padding: 0 1rem;
    justify-content: space-between;
    background-color: white;
}

.mobile-nav-logo{
    display: flex;
    align-items: center;
}

.mobile-nav-logo img {
    width: 32px;
    height: 32px;
}

.mobile-nav-logo p {
    margin-top: 0.5rem;
}

.mobile-nav-header svg {
    stroke: #666;
    stroke-width: 3px;
    width: 16px;
    height: 16px;
}

.mobile-nav-header p {
    font-size: 1rem;
}

.mobile-nav-menu ul {
    flex-direction: column;
    text-align: center;
}

.mobile-nav-menu li {
    padding: 1rem 0;
}

.mobile-nav-menu a.landing-cta{
    color: white;
}

.mobile-nav-menu a.landing-cta:visited {
    color: white;
}


.mobile-nav-menu.open{
    left:0;
}

.nav-mobile-background {
    position: fixed;
    z-index: -1;
    background-color: black;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 100vw;

    opacity: 0;
    pointer-events: none;

    transition: opacity 0.3s ease-out;
}

.mobile-nav-menu.open .nav-mobile-background {
    left: 0;
    opacity: 0.15;
    pointer-events: all;
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .mobile-nav-menu {
        width: 40%;
        left: auto;
        right: -100%;
    }
    
    .mobile-nav-menu.open {
        left: auto;
        right: 0%;
    }
}

/* Medium devices (landscape tablets, 769px and up) */
@media only screen and (min-width: 769px) {
    .mobile-nav {
        display:none;
    }

    .nav{
        display: block;
    }

    .landing-header {
        justify-content: space-between;
    }
}
