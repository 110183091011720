.hidden-phone {display: none;}
.hidden-tablet {display: none;}
.hidden-desktop {display: block;}
.col-phone {
    display: flex;
    flex-direction: column;
}

.row-phone {
    display: flex;
    flex-direction: row;
}

.row-reverse-phone {
    display: flex;
    flex-direction: row-reverse;
}

.half-width-mobile {width: 50%;}

/* Medium devices (landscape tablets, 769px and up) */
@media only screen and (min-width: 769px) {
    .hidden-tablet {display: flex;}
    .hidden-desktop {display: none;}

    .col-desktop {
        display: flex;
        flex-direction: column;
    }

    .row-desktop {
        display: flex;
        flex-direction: row;
    }

    .row-reverse-desktop {
        display: flex;
        flex-direction: row-reverse;
    }

    .half-width-mobile {width: 100%;}
}

@media only screen and (min-width: 575px) {
    .hidden-phone {display: flex;}
}