.tab {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -6;
    transform: translateX(-100vw);
    transition: transform 0.3s ease-out 0.3s;
}

.tab.selected {
    transform: translateX(0vw);
    transition: transform 0.3s ease-out;
    z-index: -5;
}

.tab .windowInner.notes-window-inner {
    margin: 0;
    height: 100%;
    border-radius: 0;
}