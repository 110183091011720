@keyframes notification {
    0%{
        height: 0;
        padding: 0;
        margin: 0;
        border-width: 0;
        transform: translateY(-200px);
    }
    5%{
        padding: 0.5rem 1rem;
        height: 40px;
        margin-bottom: 0.25rem;
        border-width: 1px;
        transform: translateY(0);
    }

    90%{
        padding: 0.5rem 1rem;
        height: 40px;
        margin-bottom: 0.25rem;
        border-width: 1px;
        transform: translateY(0);
    }

    100%{
        padding: 0.5rem 1rem;
        height: 40px;
        margin-bottom: 0.25rem;
        border-width: 1px;
        transform: translateY(0) translateX(200%);
    }

    
}

@keyframes quickClose {
    0%{ 
        padding: 0.5rem 1rem;
        height: 40px;
        margin-bottom: 0.25rem;
        border-width: 1px;
        transform: translateY(0);
    }

    50%{
        padding: 0.5rem 1rem;
        height: 40px;
        margin-bottom: 0.25rem;
        border-width: 1px;
        transform: translateY(0) translateX(200%);
    }

    100% {
        height: 0;
        padding: 0;
        margin: 0;
        border-width: 0;
        transform: translateY(0) translateX(200%);
    }
}

.notifications-container {
    position: absolute;
    top: 32px;
    right: 0;

    display: flex;
    flex-direction: column-reverse;
}

.notifications-container.inFullScreen {
    top: 70px;
}

.notification {
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    background: #C1EABD;
    color: #647682;
    border-radius: 12px 0 0 12px;
    border: 2px solid #9BD095;
    border-right: none;
    margin-bottom: 0.25rem;
    right: 0px;
    top: 1px;

    transition: top 0.3s ease-out;
}

/* .notification{ */
    /* animation: notification 5s ease-out; */
    /* animation-fill-mode: forwards; */
/* } */

.notification .button, .notification .button-container {
    margin: 0;
    padding: 0;
}

.notification .button {
    margin-left: 1rem;
    stroke: #647682;
    stroke-width: 3px;
    width: 12px;
    height: 12px;
    background: none;
    border: none;
    box-shadow: none;
}

.notification .button:hover {
    margin-left: 1rem;
    stroke: #465058;
    width: 12px;
    height: 12px;
    transform: none;
    background: none;
    border: none;
    box-shadow: none;
}

.notification .button:active, .notification .button:focus {
    transform: none;
    background: none;
    border: none;
    box-shadow: none;
}

.notification.quickClose{
    animation: quickClose 0.3s ease-out;
    animation-fill-mode: forwards;
}