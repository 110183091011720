.shadow-1 {box-shadow: 1px 1px 0 rgba(211, 219, 224, 0.3);}
.shadow-2 {box-shadow: 2px 2px 0 rgba(211, 219, 224, 0.3);}
.shadow, .shadow-3 {box-shadow: 3px 3px 0 rgba(211, 219, 224, 0.3);}
.shadow-dark-1 {box-shadow: 1px 1px 0 rgba(211, 219, 224, 0.6);}
.shadow-dark-2 {box-shadow: 2px 2px 0 rgba(211, 219, 224, 0.6);}
.shadow-dark, .shadow-dark-3 {box-shadow: 3px 3px 0 rgba(211, 219, 224, 0.6);}
.shadow-light-3 {box-shadow: 3px 3px 0 rgba(51, 60, 67, 0.15);}

/* Forced Dark Mode */
.force-dark-mode .shadow-1 {box-shadow: 1px 1px 0 rgba(52, 55, 58, 0.3);}
.force-dark-mode .shadow-2 {box-shadow: 2px 2px 0 rgba(52, 55, 58, 0.3);}
.force-dark-mode .shadow, .force-dark-mode .shadow-3 {box-shadow: 3px 3px 0 rgba(52, 55, 58, 0.3);}
.force-dark-mode .shadow-dark-1 {box-shadow: 1px 1px 0 rgba(52, 55, 58, 0.6);}
.force-dark-mode .shadow-dark-2 {box-shadow: 2px 2px 0 rgba(52, 55, 58, 0.6);}
.force-dark-mode .shadow-dark, .force-dark-mode .shadow-dark-3 {box-shadow: 3px 3px 0 rgba(52, 55, 58, 0.6);}

/* Dark Mode */
@media (prefers-color-scheme: dark) {   
    .shadow-1 {box-shadow: 1px 1px 0 rgba(52, 55, 58, 0.3);}
    .shadow-2 {box-shadow: 2px 2px 0 rgba(52, 55, 58, 0.3);}
    .shadow, .shadow-3 {box-shadow: 3px 3px 0 rgba(52, 55, 58, 0.3);}
    .shadow-dark-1 {box-shadow: 1px 1px 0 rgba(52, 55, 58, 0.6);}
    .shadow-dark-2 {box-shadow: 2px 2px 0 rgba(52, 55, 58, 0.6);}
    .shadow-dark, .shadow-dark-3 {box-shadow: 3px 3px 0 rgba(52, 55, 58, 0.6);}

    /* Forced Light theme in dark mode */
    .force-light-mode .shadow-1 {box-shadow: 1px 1px 0 rgba(211, 219, 224, 0.3);}
    .force-light-mode .shadow-2 {box-shadow: 2px 2px 0 rgba(211, 219, 224, 0.3);}
    .force-light-mode .shadow, .force-light-mode .shadow-3 {box-shadow: 3px 3px 0 rgba(211, 219, 224, 0.3);}
    .force-light-mode .shadow-dark-1 {box-shadow: 1px 1px 0 rgba(211, 219, 224, 0.6);}
    .force-light-mode .shadow-dark-2 {box-shadow: 2px 2px 0 rgba(211, 219, 224, 0.6);}
    .force-light-mode .shadow-dark, .force-light-mode .shadow-dark-3 {box-shadow: 3px 3px 0 rgba(211, 219, 224, 0.6);}
}