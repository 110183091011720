.diceTray.stone {
    border: 6px solid #abc9dc;
    box-shadow: 1px 1px 0 #85a4b7,
    2px 2px 0 #85a4b7,
    3px 3px 0 #85a4b7,
    4px 4px 0 #85a4b7,
    5px 5px 0 #85a4b7,
    1px 1px 0 #85a4b7 inset,
    2px 2px 0 #85a4b7 inset,
    3px 3px 0 #85a4b7 inset,
    4px 4px 0 #85a4b7 inset,
    5px 5px 0 #85a4b7 inset,
    
    8px 8px 0 rgba(211, 219, 224, 0.3);

    transition: transform 0.1s ease-out, box-shadow 0.1s ease-out;
}

.diceTray.stone.locked {
    box-shadow: 1px 1px 0 #85a4b7,
    2px 2px 0 #85a4b7,
    3px 3px 0 #85a4b7,
    4px 4px 0 #85a4b7,
    5px 5px 0 #85a4b7,
    1px 1px 0 #85a4b7 inset,
    2px 2px 0 #85a4b7 inset,
    3px 3px 0 #85a4b7 inset,
    4px 4px 0 #85a4b7 inset,
    5px 5px 0 #85a4b7 inset,
    
    0px 0px 0 rgba(211, 219, 224, 0.3);
}