.Icon svg {
    width: 100%;
    height: 100%;
}

.Icon.brush-option {
    fill: #647682;
}

.Icon.brush-option:hover {
    fill: #64C0F0;
}

.active .Icon.brush-option {
    fill: #64C0F0;
}

.Icon.spray-option {
    fill: #647682;
    height: 16px;
}

.Icon.spray-option:hover {
    fill: #64C0F0;
}

.active .Icon.spray-option {
    fill: #64C0F0;
}

.Icon.circle-option {
    stroke: #647682;
}

.Icon.circle-option:hover {
    stroke: #64C0F0;
}

.active .Icon.circle-option {
    stroke: #64C0F0;
}

.Icon.square-option {
    stroke: #647682;
}

.Icon.square-option:hover {
    stroke: #64C0F0;
}

.active .Icon.square-option {
    stroke: #64C0F0;
}

.Icon.line-option {
    stroke: #647682;
}

.Icon.line-option:hover {
    stroke: #64C0F0;
}

.active .Icon.line-option {
    stroke: #64C0F0;
}

.Icon.bucket-option {
    fill: #647682;
}

.Icon.bucket-option:hover {
    fill: #64C0F0;
}

.active .Icon.bucket-option {
    fill: #64C0F0;
}

.Icon.undo {
    fill: #647682;
}

.Icon.undo:hover {
    fill: #64C0F0;
}

.active .Icon.undo {
    fill: #64C0F0;
}

.Icon.redo {
    fill: #647682;
}

.Icon.redo:hover {
    fill: #64C0F0;
}

.active .Icon.redo {
    fill: #64C0F0;
}

.Icon.arrow-left {
    transform: rotate(180deg);
}

.diceValueIcon {
    position: relative;
    width: 32px;
    height: 32px;
    cursor: pointer;
    user-select: none;

    transition: transform 0.3s ease-out;
}

.remote .diceValueIcon {
    cursor: default;
    pointer-events: none;
}

.diceValueIcon svg {
    width: 100%;
    height: 100%;
}

.diceValueIcon svg.size-75 {
    width: 0.75rem;
    height: 0.75rem;
}

svg.size-1 {
    width: 1rem;
    height: 1rem;
}

.diceValueIcon p {
    position: absolute;
    bottom: 0px;
    left: 1px;
    color: black;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    width: calc(100% - 0.1rem);
    height: 100%;
    line-height: 32px;
    vertical-align: middle;
}

.diceHistoryEntry.black .diceValueIcon p {
    color: white;
}

.diceValueIcon.small p {
    font-size: 0.8rem;
}

.diceValueIcon.xsmall p {
    font-size: 0.7rem;
}

.diceValueIcon.D100 p {
    font-size: 0.9rem;
}

.diceValueIcon.D6 p {
    width: 80%;
    height: 90%;
}

.diceValueIcon.D4 p {
    height: 90%;
    left: 0;
}

.diceValueIcon.drop {
    position: relative;
    opacity: 0.25;
}

.diceValueIcon.drop::after {
    position: absolute;
    content: '';
    top: 50%;
    left: -25%;
    border-bottom: 1px solid #465058;
    width: 150%;
    transform: rotate(45deg);
}

/* .diceValueIcon.drop p {
    color: #465058;
} */

.diceValueIcon.failure {
    color: #465058;
}

.diceValueIcon.failure p {
    color: #465058;
}

.diceValueIcon:active {
    transform: scale(0.8)
}

.diceValueIcon.rolling svg {
    animation: roll 0.6s ease-in-out infinite;
}

.diceValueIcon.rolling p {
    animation: roll 0.6s ease-in-out infinite;
}

@keyframes roll {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.hasReRollIcon {
    position: absolute;
    width: auto;
    height: 12px;
    top: -4px;
    right: -25%;
    display: flex;
    padding: 3px;

    align-items: center;
}

.hasReRollIcon span {
    font-size: 8px;
    line-height: 8px;
    margin-left: 2px;
}