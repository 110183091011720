.landing-container{
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.landing-container a {
    text-decoration: none;
}

.landing-container.navMenuOpen {
    overflow-y: auto;
}

.landing-container.navMenuOpen {
    overflow-y: hidden;
    height: 100vh;
}

.landing-container h1 {
    font-size: 3rem;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: bold;
    text-transform: none;
    text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.15);
}

.landing-container h2 {
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: 1.8rem;
    font-weight: bold;
}

.landing-login-nav li {
    padding-left: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing-main {
    position: relative;
    height: 886px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: linear-gradient(to top, rgb(46, 118, 155) 0%, rgba(156, 197, 234) 70%, rgba(138, 179, 215) 100%);
}

.landing-main-inner {
    width: 90%;
    margin: margin 0;
    text-align: center;
    color: white;
    font-weight: bold;
    margin-top: 32px;
    z-index: 1;
}

.landing-mountain-background {
    position: absolute;
    background-image: url("../../assets/mountain-background.svg");
    background-position-y: 0;
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    pointer-events: none;
}


/* Forced Dark Mode */
.force-dark-mode .landing-mountain-background {background-image: url("../../assets/mountain-background-dark.svg");}
.force-dark-mode .landing-main {background-image: linear-gradient(to top, rgb(39, 100, 130) 0%, rgba(39, 100, 130) 70%, rgba(31, 80, 110) 100%);}
/* Dark Mode */
@media (prefers-color-scheme: dark) {    
    .landing-mountain-background {background-image: url("../../assets/mountain-background-dark.svg");}
    .landing-main {background-image: linear-gradient(to top, rgb(39, 100, 130) 0%, rgba(39, 100, 130) 70%, rgba(31, 80, 110) 100%);}
    /* Forced Light theme in dark mode */
    .force-light-mode .landing-mountain-background {background-image: url("../../assets/mountain-background.svg");}
    .force-light-mode .landing-main {background-image: linear-gradient(to top, rgb(46, 118, 155) 0%, rgba(156, 197, 234) 70%, rgba(138, 179, 215) 100%);}
}

.main-logo {
    width: 240px;
    margin: 0 auto;
}

.landing-main-inner p {
    font-size: 1rem;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.15);
    margin: 1rem 0;
}

.landing-main-inner button {
    margin-top: 1rem;
}

.landing-cta {
    display: inline-block;
    background-color: #e17e7e;
    color: white;
    font-family: 'Poppins', serif;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    border: none;
    box-shadow: 2px 2px 0 rgba(0, 0, 0, 0.30);
    transition: all 0.1s ease-out;
    cursor: pointer;
    font-weight: bold;
}

.landing-cta.red{
    background-color: #E28585;
}

.landing-cta:hover, .landing-cta:focus {
    transform: scale(1.01);
    box-shadow: 3px 3px 0 rgba(0, 0, 0, 0.3);
    outline: none;
}

.landing-cta:active {
    transform: scale(0.99);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.15);
    outline: none;
}

.landing-footer {
    position: relative;
    height: 500px;
    background-color: #1F506E;
    background-image: url('../../assets/city-background.svg');
    background-repeat: repeat-x;
    background-size: 75%;
    background-position-y: bottom;

    text-align: center;
    z-index: 1;
}

.landing-footer-logo {
    width:256px;
}

.landing-footer h1{
    color: white;
}

.landing-footer footer{
    position: absolute;
    bottom: 0;
    background-image: url('../../assets/clouds-background.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: 208px;
    background-position-x: center;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 1rem;
    z-index: -1;

    color: #647682;
    font-weight: bold;
}


.landing-footer-right a:nth-child(2) {
    margin-left: 1rem;
}

.landing-footer footer p, .landing-footer footer a {
    font-size: 0.5rem;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .landing-header ul {
        font-size: 0.75rem;
    }

    .landing-main{
        height: 600px;
    }

    .landing-main-inner {
        padding-left: 1rem;
        padding-right: 1rem;
    }
    .landing-main-inner h1 {
        font-size: 2rem;
    }

    .landing-main-inner p {
        font-size: 0.9rem;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .main-logo {
        width: 312px;
    }

    .landing-main{
        height: 776px;
    }

    .landing-main-inner {
        margin-top: 98px;
        width:90%;
    }

    .landing-mountain-background{
        background-position-y: 150px;
    }
}

/* Medium devices (landscape tablets, 769px and up) */
@media only screen and (min-width: 769px) {

}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .landing-main-inner {
        width:75%;
    }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1440px) {
    .landing-main {
        height: 996px;
    }

    .landing-main-inner {
        max-width: 820px;
    }

    .landing-mountain-background {
        background-position-y: 150px;
    }
}

/* Extra large devices (large laptops and desktops, 1600px and up) */
@media only screen and (min-width: 1720px) {
    .landing-main {
        height: 756px;
    }

    .landing-mountain-background {
        background-position-y: -340px;
    }

    .landing-footer footer {
        background-position-y: 0;
    }
}

/* Extra large devices (large laptops and desktops, 1600px and up) */
@media only screen and (min-width: 2060px) {
    .landing-main {
        height: 756px;
    }

    .landing-mountain-background {
        background-position-y: -620px;
    }
}


/* Extra large devices (large laptops and desktops, 1600px and up) */
@media only screen and (min-width: 2700px) {
    .landing-mountain-background {
        background-position-y: -720px;
    }
}