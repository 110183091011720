.tab-nav-header {
    width: 100%;
    display: flex;
    margin-top: 1rem;
}

.tab-nav-tab {
    display: none;
    width: 100%;
    height: 100%;
}

.tab-nav-tab.active {
    display: block;
}

.tab-nav-button {
    height: 32px;
    border: none;
    outline: none;
    background: none;
    font-weight: bold;
    color: #647682;
    flex: 1 1;
    margin: 0 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 0px solid #FFC170;

    transition: all 0.1s ease-out;
}

.tab-nav-button:hover {
    color: #465058;
}

.tab-nav-button.active {
    color: #FFC170;
    border-bottom: 3px solid #FFC170;
}