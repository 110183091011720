.bg-none {
    background: none;
}

.bg-ice {
    background-color: #f2f9fd;
}

.bg-light-blue {
    background-color: #b4e1e9
}

.bg-blue {
    background-color: #6EADC6
}

.bg-dark-blue {
    background-color: #7faabc
}

.bg-midnight-blue {
    background-color: #404d55;
}

.bg-red {
    background-color: #e17e7e
}

.bg-light-red {
    background-color: #EC9898
}

.bg-dark-red {
    background-color: #DF5353
}

.bg-green {
    background-color: #9bd095
}

.bg-light-green {
    background-color: #BCE0B8
}

.bg-dark-green {
    background-color: #87C77F
}

.bg-yellow {
    background-color: #FFDCAD
}

.bg-light-yellow {
    background-color: #ffedd6
}

.bg-dark-yellow {
    background-color: #f5c88e
}

.bg-cyan {
    background-color: #b4e1e9
}

.bg-light-cyan {
    background-color: #f2f9fd
}

.bg-dark-cyan {
    background-color: #90D3DF
}

.bg-purple {
    background-color: #B18CD9;
}

.bg-pink {
    background-color: #FFC8E5;
}

.bg-orange {
    background-color: #FFB864;
}

.bg-none {
    background: none;
}

.bg-white {
    background-color: #FBFBFB;
}

.bg-shade-1 {
    background-color: #f5f9fa;
}

.bg-shade-2 {
    background-color: #eceff3;
}

.bg-shade-3 {
    background-color: #dee1e8;
}

.bg-shade-4 {
    background-color: #ccd5db;
}

.bg-shade-5 {
    background-color: #abb6bf;
}

.bg-shade-6,
.bg-main {
    background-color: #647682;
}

.bg-shade-7 {
    background-color: #465058;
}

.bg-shade-8 {
    background-color: #333c43;
}

.bg-shade-9 {
    background-color: #21262c;
}


/* Hover */
.bg-hover-shade-2:hover {
    background-color: #eceff3;
}

/* Forced Dark Mode */
.force-dark-mode .bg-white {
    background-color: #21262c;
}

.force-dark-mode .bg-shade-1 {
    background-color: #333c43;
}

.force-dark-mode .bg-shade-2 {
    background-color: #465058;
}

.force-dark-mode .bg-shade-3 {
    background-color: #647682;
}

.force-dark-mode .bg-shade-4 {
    background-color: #abb6bf;
}

.force-dark-mode .bg-shade-5 {
    background-color: #ccd5db;
}

.force-dark-mode .bg-shade-6,
.force-dark-mode .bg-main {
    background-color: #dee1e8;
}

.force-dark-mode .bg-shade-7 {
    background-color: #eceff3;
}

.force-dark-mode .bg-shade-8 {
    background-color: #f5f9fa;
}

.force-dark-mode .bg-shade-9 {
    background-color: #FBFBFB;
}

.force-dark-mode .bg-none {
    background: none;
}

.bg-shade-4-neutral {
    background-color: #ccd5db;
}

.bg-shade-5-neutral {
    background-color: #abb6bf;
}

.bg-main-neutral {
    background-color: #647682;
}

.bg-shade-7-neutral {
    background-color: #465058;
}

/* hover */
.force-dark-mode .bg-hover-shade-2:hover {
    background-color: #465058;
}


/* Dark Mode */
@media (prefers-color-scheme: dark) {
    .bg-white {
        background-color: #21262c;
    }

    .bg-shade-1 {
        background-color: #333c43;
    }

    .bg-shade-2 {
        background-color: #465058;
    }

    .bg-shade-3 {
        background-color: #647682;
    }

    .bg-shade-4 {
        background-color: #abb6bf;
    }

    .bg-shade-5 {
        background-color: #ccd5db;
    }

    .bg-shade-6,
    .bg-main {
        background-color: #dee1e8;
    }

    .bg-shade-7 {
        background-color: #eceff3;
    }

    .bg-shade-8 {
        background-color: #f5f9fa;
    }

    .bg-shade-9 {
        background-color: #FBFBFB;
    }

    .bg-none {
        background: none;
    }


    /* hover */
    .bg-hover-shade-2:hover {
        background-color: #465058;
    }


    /* Forced Light theme in dark mode */
    .force-light-mode .bg-white {
        background-color: #FBFBFB;
    }

    .force-light-mode .bg-shade-1 {
        background-color: #f5f9fa;
    }

    .force-light-mode .bg-shade-2 {
        background-color: #eceff3;
    }

    .force-light-mode .bg-shade-3 {
        background-color: #dee1e8;
    }

    .force-light-mode .bg-shade-4 {
        background-color: #ccd5db;
    }

    .force-light-mode .bg-shade-5 {
        background-color: #abb6bf;
    }

    .force-light-mode .bg-shade-6,
    .force-light-mode .bg-main {
        background-color: #647682;
    }

    .force-light-mode .bg-shade-7 {
        background-color: #465058;
    }

    .force-light-mode .bg-shade-8 {
        background-color: #333c43;
    }

    .force-light-mode .bg-shade-9 {
        background-color: #21262c;
    }

    .force-light-mode .bg-none {
        background: none;
    }

    /* hover */
    .force-light-mode .bg-hover-shade-2:hover {
        background-color: #eceff3;
    }
}