.default-button {
    padding: 0.75rem 1rem;

}

/* -------------------------- color support -------------------------- */
.default-button {color: white; background-color: #7FAABC;}
.default-button.main {color: white; background-color: #7FAABC;}
.default-button.white {color: #647682; background-color: #FBFBFB;}
.default-button.black {color: white; background-color: #404D55;}
.default-button.red {color: white; background-color: #E17E7E;}
.default-button.blue {color: #465058; background-color: #B4E1E9;}
.default-button.green {color: white; background-color: #9BD095;}
.default-button.yellow {color: #647682; background-color: #FFEDD6;}

.default-button:hover, .default-button:focus, .default-button:active {background-color: #8dc2d8;}
.default-button.main:hover, .default-button.main:focus, .default-button.main:active {background-color: #8dc2d8;}
.default-button.white:hover, .default-button.white:focus, .default-button.white:active { background-color: #d3cece;}
.default-button.black:hover, .default-button.black:focus, .default-button.black:active {background-color: #2e383f;}
.default-button.red:hover, .default-button.red:focus, .default-button.red:active {background-color: #ca6565;}
.default-button.blue:hover, .default-button.blue:focus, .default-button.blue:active { background-color: #bcecf5;}
.default-button.green:hover, .default-button.green:focus, .default-button.green:active {background-color: #7fbb78;}
.default-button.yellow:hover, .default-button.yellow:focus, .default-button.yellow:active { background-color: #e6c9a3;}

.force-dark-mode .default-button.black {color: #647682; background-color: #FBFBFB;}
.force-dark-mode .default-button.white {color: white; background-color: #404D55;}
.force-dark-mode .default-button.white:hover, .force-dark-mode .default-button.white:focus, .force-dark-mode .default-button.white:active { background-color: #2e383f;}
.force-dark-mode .default-button.black:hover, .force-dark-mode .default-button.black:focus, .force-dark-mode .default-button.black:active {background-color: #d3cece;}

/* Dark Mode */
@media (prefers-color-scheme: dark) {    
    .default-button.black {color: #647682; background-color: #FBFBFB;}
    .default-button.white {color: white; background-color: #404D55;}
    .default-button.white:hover, .default-button.white:focus, .default-button.white:active { background-color: #2e383f;}
    .default-button.black:hover, .default-button.black:focus, .default-button.black:active {background-color: #d3cece;}
    
    /* Forced Light theme in dark mode */
    .default-button.white {color: #647682; background-color: #FBFBFB;}
    .default-button.black {color: white; background-color: #404D55;}
    .default-button.white:hover, .default-button.white:focus, .default-button.white:active { background-color: #d3cece;}
    .default-button.black:hover, .default-button.black:focus, .default-button.black:active {background-color: #2e383f;}
}