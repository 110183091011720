.folder-inner {
    position: relative;
    transition: background-color 0.6s ease-out, border-color 0.6s ease-out
}

.folder-inner .smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
    overflow: visible;
}

.smooth-dnd-container {
    height: 100%;
}

.items-drop-preview {
    border: 1px dashed;
    height: 40px;
    border-radius: 8px;
}

.folder-drop-preview {
    border: 1px dashed;
    height: 38px;
    width: 100%;
    margin: 0.5rem;
    border-radius: 8px;
}

.foldersListContainer {
    flex: 1;
}