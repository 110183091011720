.premium-signup-window {
    text-align: center;
    color: #647682;
    padding: 0 1rem;
    height: 100%;
}

.billing-interval-toggle {
    cursor: pointer;
    position: relative;
    border-radius: 16px;
    border: 1px solid;
    margin: 1rem auto;
    padding: 3px;
}

.billing-interval-toggle .option {
    font-size: 0.9rem;
    padding: 0 1rem;
    border-radius: 16px;
    font-weight: bold;
    color: #5BA353;
    background-color: transparent;
    transition: color 0.3s ease-out, background-color 0.3s ease-out;
}

.billing-interval-toggle .option.active {
    background-color: #9BD095;
    color: white;
}

.billing-interval-sticker {
    position: absolute;
    right: -32px;
    top: -12px;
    background: #9BD095;
    border-radius: 12px;
    font-size: 0.5rem;
    color: white;
    padding: 3px 6px;
}

#billing-interval-toggle-input {
    display: none;
}