.bumper {
    position: absolute;
    background-color: white;
    top: 0;
    height: 100%;
    width: 0;
    opacity: 0;


    z-index: -2;
    transition: opacity 0.3s ease-out, width 0.2s ease-out;
}

.left-bumper {
    left: 0;
}

.right-bumper {
    right: 0;
}

.bumper.active, .bumper.active {
    opacity: 0.3;
    width: 30%;
}