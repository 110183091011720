.item, .folder {
    position: relative;
    /* width: calc(50% - 0.25rem); */
    width: 100%;
    height: 48px;
    transition: height 0.3s ease-out;
}

.item.open, .folder.open {
    height: 200px;
}

.item--preview {
    height: 0;
    transition: height 0.3s ease-out;
    overflow: hidden;
    margin-bottom: -48px;
    border-radius: 0 0 8px 8px;
}

.item.open .item--preview, .folder.open .folder--documents {
    height: 160px;
}

.item .item--preview, .folder .folder--documents {
    position: relative;
    top: -24px;
    z-index: 0;
}

.item .item-inner {
    transform: scale(1);
    transition: transform 0.3s ease-out;
    z-index: 1;
}

.item-inner:hover {
    transform: scale(1.05);
}

.item:nth-child(even) {
    margin-right: 0;
}

.item .window-title-input {
    width: 75%;
    margin: 0;
    padding: 4px;
}

.item .window-title-display, .folder .window-title-display {
    width: 75%;
    margin: 0;
    text-align: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.isOwner.window-title-display:hover {
    text-decoration: underline;

}

.folderName.window-title-display {
    width: 160px;
    border-radius: 8px;
    padding: 4px;
}

.folderName.window-title-input {
    width: 160px;
}

.folderName.isOwner:hover {
    /* background-color: rgba(255,255,255, 0.6); */
    text-decoration: underline;
 }

.itemHoverIconWrapper, .folderHoverIconWrapper {
    position: absolute;
    right: 2px;
    /* width:30%; */
    height: 90%;
    top: 5%;
    display: flex;
    justify-content: flex-end;
    opacity: 0;
    pointer-events: none;
    transform: translateX(-16px) scale(1);
    transition: transform 0.1s ease-out, opacity 0.3s ease-out;
}

.folderHoverIconWrapper {
    top: 0;
    height: 100%;
    max-height: 30px;
}

.item:hover .itemHoverIconWrapper, .folder:hover .folderHoverIconWrapper {
    pointer-events: all;
    opacity: 1;
    transform: translateX(-6px) scale(1);
}

.folder:hover .folderHoverIconWrapper {
    transform: translateX(0px) scale(1);
}

.itemHoverIconWrapper svg, .folderHoverIconWrapper svg {
    width: 24px;
    height: 100%;
    transform: scale(1);
    transition: transform 0.1s ease-out;
}

.folderHoverIconWrapper svg {
    width: 18px;
    margin-right: 0.5rem;
}

.item .itemHoverIconWrapper:hover svg {
    transform: scale(1.5);
}

.folder .folderHoverIcon:hover {
    transform: scale(1.3);
}

.folder.open.default-folder {
    height: auto;
    min-height: 200px;
}

.default-folder .folder-inner, .default-folder .folder--folderDocuments {
    min-height: 200px;
}

.folder-0.default-folder{
    padding-top: 0.5rem;
}
 
.folder .FolderEditMode .list-item:hover {
    background-color: rgba(100, 118, 130, 0.5);
}

.FolderEditMode--lists {
    background-color: rgba(100, 118, 130, 0.5);
}

.folder-topbar {
    height: 36px;
    min-height: 36px;
}

.folder-inner.dragInProgress .item {
    animation-name: keyframes1;
    animation-duration: 300ms;
    animation-delay: calc(var(--animation-order) * 100ms + 100ms);
    animation-fill-mode: both;
    animation-timing-function: ease-in-out;

    transform-origin: 50% 10%;
}

@keyframes keyframes1 {
    0% {
      transform: rotate(-0.3deg);
      animation-timing-function: ease-in;
    }

    50% {
      transform: rotate(0.3deg);
      animation-timing-function: ease-out;
    }

    100% {
        transform: rotate(0deg);
        animation-timing-function: ease-out;
    }
  }

.dragging-item .notOwner:after {
      content: 'No drag permissons';
      position: absolute;
      top: 0; 
      left: 0;
      width: 100%;
      height: 100%;
      font-size: small;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: inherit;
  }

.dragging-item .notOwner {
    opacity: 0.8;
}

.dragging-item .notOwner ~ .item--preview {
    opacity: 0;
}