.textInput-container {
    text-align: left;
}

.textInput {
    padding: 0.75rem;
    /* border: 1px solid #647682; */
    border: 2px solid #CCD5DB;
    border-radius: 14px;
    /* box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.15); */
    width: 100%;
    color: #647682;
    font-weight: 500;

    transition: all 0.1s ease-out;
}

.textInput-label {
    font-size: 0.75rem;
    display: block;
    margin: 0.5rem;
    color: #647682;
}

.textInput-error {
    color: #D92121;
    font-weight: bold;
    margin: 0.5rem;
}

.textInput-shake {
    animation: textInput-shake infinite 0.3s;
}

@keyframes textInput-shake {
    0% { transform: translateX(0);}
    25% { transform: translateX(3px);}
    50% { transform: translateX(-3px);}
    75% { transform: translateX(3px);}
    100% { transform: translateX(0);}
}

.textInput::placeholder {
    font-size: 14px;
    color: #ccd5db;
    font-weight: 400;
}

.textInput:hover {
    background-color: #F5F9FA;
}

/* Forced Dark Mode */
.force-dark-mode .textInput::placeholder {color: #647682;}
.force-dark-mode .textInput:hover {background-color: #21262c;}
/* Dark Mode */
@media (prefers-color-scheme: dark) {
    .textInput::placeholder {color: #647682;}
    .textInput:hover {background-color: #21262c;}
    /* Forced Light theme in dark mode */
    .force-light-mode .textInput::placeholder {color: #ccd5db;}
    .force-light-mode .textInput:hover {background-color: #F5F9FA;}
}

.textInput:focus, .textInput:active {
    outline: none;
    border-color: #ABB6BF;
    /* transform: translateY(-2px) scale(1.01); */
    /* box-shadow: 6px 6px 0 rgba(0, 0, 0, 0.10); */
}