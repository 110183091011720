.checkbox {
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  text-align: left;
}

.checkbox .value {
  padding-top: 0.25rem;
  display: block;
}

.checkbox-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid #ABB6BF;
  border-radius: 8px;
}

.checkbox:hover .checkbox-input~.checkmark {
  background-color: #B4E1E9;
}

.checkbox .checkbox-input:checked~.checkmark {
  background-color: #B4E1E9;
}

.force-dark-mode .checkbox:hover .checkbox-input~.checkmark {
  background-color: #21262c;
}

.force-dark-mode .checkbox .checkbox-input:checked~.checkmark {
  background-color: #B4E1E9;
}

/* Dark Mode */
@media (prefers-color-scheme: dark) {
  .checkbox:hover .checkbox-input~.checkmark {
    background-color: #21262c;
  }

  .checkbox .checkbox-input:checked~.checkmark {
    background-color: #B4E1E9;
  }

  /* Forced Light theme in dark mode */
  .force-light-mode .checkbox:hover .checkbox-input~.checkmark {
    background-color: #FBFBFB;
  }

  .force-light-mode .checkbox .checkbox-input:checked~.checkmark {
    background-color: #B4E1E9;
  }
}



/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox .checkbox-input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
  left: 8px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid #4d4d4d;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}