.social-media-icons {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 2rem;
}

.social-media-icons .Icon {
    width: 32px;
    height: 32px;
    margin: 0 1rem;
}

.social-media-icons .Icon svg {
    stroke: none;
    fill: #647682;
    transition: fill 0.1s ease-out;
}

.social-media-icons .iconButton:hover svg {
    stroke: none;
}

.social-media-icons .iconButton:hover .Icon svg g {
    stroke: none;
}

.settings-social-icons .social-media-icons .Icon {
    width: 32px;
    height: 32px;
    margin: 0 1rem;
}

.settings-social-icons .social-media-icons {
    margin-bottom: 0;
    margin-top: 1.5rem;
}

/* Medium devices (landscape tablets, 769px and up) */
@media only screen and (min-width: 769px) {   
    .social-media-icons .Icon {
        margin: 0 0.5rem;
    }
     
    .social-media-icons .Icon svg {
        stroke: none;
        fill: white;
        transition: fill 0.1s ease-out;
    }

    .settings-social-icons .Icon svg{
        fill: #647682;
    }
}