#dice-layer {
    position: absolute;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    z-index: 10000000;
}

#dice-layer-ui {
    position: absolute;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
    z-index: 10000001;
}

.dg.ac {
    z-index: 100000000 !important;
}