h1 {
  text-transform: uppercase;
  color: #647682;
  margin: 0;
}

h2 {
  color: #647682;
  margin: 0;
}

h3 {
  margin: 0;
}

p {
  margin: 0;
}

.t-centered, .t-center {
    text-align: center;
}

.t-left {
    text-align: left;
}

.t-right {
    text-align: right;
}
  
.t-bold {
    font-weight: bold;
}

.t-lighter {
    font-weight: lighter;
}

.t-normal {
    font-weight: normal;
}

.t-italic {
  font-style: italic;
}

.t-uppercase {
  text-transform: uppercase;
}

.t-capitalize {
  text-transform: capitalize;
}

.t-lowercase {
  text-transform: lowercase;
}

.t-clear-transform {
  text-transform: none;
}
  
.link {
  color: #647682;
  font-weight: bold;
  text-decoration: none;
}
  
.link:hover {
  text-decoration: underline;
}
  
.whitespace-nowrap {
  white-space: nowrap;
}

.sans-serif {
  font-family: 'Open Sans', sans-serif;
}
  
.serif {
  font-family: 'Poppins', serif;
}

.t-normal {
  font-size: 1rem;
}

.t-small {
  font-size: 0.75rem;
}

.t-large {
  font-size: 1.25rem;
}

.t-overflow-elipses {
  text-overflow: ellipsis;
  overflow: hidden;
}

@media only screen and (max-width: 769px) {
  .t-centered-mobile, .t-center-mobile {
    text-align: center;
  }

  .t-left-mobile {
    text-align: left;
  }

  .t-right-mobile {
    text-align: left;
  }
}