.dice-drawer {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 78px;
    height: 128px;
    padding-top: 0.5rem;
    border-radius: 12px 0 0 0;
    border-left: 2px solid;
    border-top: 2px solid;
    z-index: -1;

    transition: width 0.1s ease-out, height 0.3s ease-out, left 0.3s ease-out;
}

.dice-drawer.open {
    height: 470px;
    width: 100%;
    transition: width 0.3s ease-out, height 0.3s ease-out, left 0.3s ease-out;
}

.dice-drawer .diceOptions {
    display: none;
    opacity: 0;
    animation: fade-in 0.3s ease-out 0.3s forwards;
}

.dice-drawer.open .diceOptions {
    display: block;
}

.diceDrawer-inner {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column-reverse;

    height: 100%;
}

.dice-drawer.open .diceDrawer-inner {
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0 2rem;
}

.diceDrawer-dice-container {
    display: flex;
    user-select: none;
    flex-direction: column-reverse;
    margin: 0 0.75rem;
}

.diceDrawer-expand-button .Icon {
    max-width: 24px;
}

.diceDrawer-expand-button svg {
    stroke: #ABB6BF;
    width: 16px;
}

.diceDrawer-expand-button:hover svg {
    stroke: #647682;
}


.diceDrawer-dice-button {
    padding: 8px;
}

.diceDrawer-dice-button:hover {
    transform: scale(1.1);
}

.diceDrawer-dice-button:active,
.diceDrawer-dice-button:focus {
    transform: scale(0.9);
}

.diceDrawer-dice-button img {
    max-width: 42px;
    max-height: 42px;
}

.diceDrawer-optionsButton {
    height: 24px;
    width: 24px;
    stroke: none;
    fill: #ABB6BF;
}

@media (prefers-color-scheme: dark) {
    .diceDrawer-optionsButton {
        stroke: none;
    }

    .force-light-mode .diceDrawer-optionsButton {
        stroke: none;
    }
}

.dice-drawer.optionsOpen .diceDrawer-optionsButton {
    transform: rotate(90deg);
}

.diceDrawer-optionsButton svg {
    height: 24px;
    width: 24px;
}

.diceDrawer-optionsButton:hover {
    fill: #647682;
    stroke: none;
}

.diceOptionsInput {
    width: 48px;
}

.diceOptionsInput .textInput {
    text-align: center;
}

/* Medium devices (landscape tablets, 769px and up) */
@media only screen and (min-width: 769px) {
    .dice-drawer {
        top: 0;
        left: 0;
        height: 52px;
        width: 190px;
        border-left: 0;
        border-top: 0;
        border-right: 2px solid;
        border-bottom: 2px solid;
        border-radius: 0 0 12px 0;
        padding-left: 32px;

        padding-top: 0;
        transition: width 0.1s ease-out, height 0.1s ease-out, left 0.3s ease-out;
    }

    .dice-drawer.open .diceOptions {
        display: none;
        opacity: 0;
    }

    .dice-drawer.optionsOpen .diceOptions {
        display: flex;
        animation: fade-in 0.3s ease-out 0.3s forwards;
    }

    .diceDrawer-optionsButton {
        display: flex;
    }

    .dice-drawer.chest-open {
        left: 310px;
    }

    .diceDrawer-inner {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        justify-content: flex-start;

        height: 100%;
    }

    .diceDrawer-dice-container {
        flex-direction: row;
        overflow: none;
    }

    .dice-drawer.open {
        width: 560px;
        height: 52px;
        transition: width 0.3s ease-out, height 0.1s ease-out, left 0.3s ease-out;
    }

    .dice-drawer.open .diceDrawer-inner {
        padding: 0 1rem;
        flex-direction: column;
        align-items: flex-end;
        justify-content: flex-start;
    }

    .diceDrawer-inner-top {
        justify-content: space-evenly;
    }

    .dice-drawer.optionsOpen {
        height: 240px;
    }

    .diceDrawer-optionsButton {
        margin-right: 0.5rem;
    }

    .diceDrawer-expand-button {
        padding: 4px 12px 0 8px;
    }

    .diceDrawer-expand-button.open {
        transform: rotate(180deg);
    }

    .diceDrawer-expand-button .Icon {
        max-width: 8px;
    }

    .diceDrawer-dice-button img {
        max-width: 38px;
        max-height: 38px;
    }

    .diceDrawer-expand-button svg {
        width: 8px;
    }
}