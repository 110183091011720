.button {
    font-weight: bold;
    text-transform: uppercase;
    border-radius: 12px;
    border: none;
    outline: none;
    transition: all 0.1s ease-out;
    cursor: pointer;
    pointer-events: all;
}

.button:hover {transform: scale(1.01);}
.button:active {transform: scale(0.99);}

.button.loading {
    pointer-events: none;
    opacity: 0.5;
}


/* -------------------------- Shadow Support -------------------------- */
.button.shadow-1:hover {box-shadow: 2px 2px 0 rgba(211, 219, 224, 0.3);}
.button.shadow-1:active {box-shadow: 0px 0px 0 rgba(211, 219, 224, 0.3);}

.button.shadow-2:hover {box-shadow: 3px 3px 0 rgba(211, 219, 224, 0.3);}
.button.shadow-2:active {box-shadow: 1px 1px 0 rgba(211, 219, 224, 0.3);}

.button.shadow-3:hover, .button.shadow:hover {box-shadow: 4px 4px 0 rgba(211, 219, 224, 0.3);}
.button.shadow-3:active, .button.shadow:active {box-shadow: 2px 2px 0 rgba(211, 219, 224, 0.3);}

/* shadow 0.6 */
.button.shadow-dark-1:hover {box-shadow: 2px 2px 0 rgba(211, 219, 224, 0.6);}
.button.shadow-dark-1:active {box-shadow: 0px 0px 0 rgba(211, 219, 224, 0.6);}

.button.shadow-dark-2:hover {box-shadow: 3px 3px 0 rgba(211, 219, 224, 0.6);}
.button.shadow-dark-2:active {box-shadow: 1px 1px 0 rgba(211, 219, 224, 0.6);}

.button.shadow-dark-3:hover, .button.shadow-dark:hover {box-shadow: 4px 4px 0 rgba(211, 219, 224, 0.6);}
.button.shadow-dark-3:active, .button.shadow-dark:active {box-shadow: 2px 2px 0 rgba(211, 219, 224, 0.6);}


/* Force Dark mode */
.force-dark-mode .button.shadow-1:hover {box-shadow: 2px 2px 0 rgba(52, 55, 58, 0.3);}
.force-dark-mode .button.shadow-1:active {box-shadow: 0px 0px 0 rgba(52, 55, 58, 0.3);}

.force-dark-mode .button.shadow-2:hover {box-shadow: 3px 3px 0 rgba(52, 55, 58, 0.3);}
.force-dark-mode .button.shadow-2:active {box-shadow: 1px 1px 0 rgba(52, 55, 58, 0.3);}

.force-dark-mode .button.shadow-3:hover, .force-dark-mode .button.shadow:hover {box-shadow: 4px 4px 0 rgba(52, 55, 58, 0.3);}
.force-dark-mode .button.shadow-3:active, .force-dark-mode .button.shadow:active {box-shadow: 2px 2px 0 rgba(52, 55, 58, 0.3);}

/* shadow dark */
.force-dark-mode .button.shadow-dark-1:hover {box-shadow: 2px 2px 0 rgba(52, 55, 58, 0.6);}
.force-dark-mode .button.shadow-dark-1:active {box-shadow: 0px 0px 0 rgba(52, 55, 58, 0.6);}

.force-dark-mode .button.shadow-dark-2:hover {box-shadow: 3px 3px 0 rgba(52, 55, 58, 0.6);}
.force-dark-mode .button.shadow-dark-2:active {box-shadow: 1px 1px 0 rgba(52, 55, 58, 0.6);}

.force-dark-mode .button.shadow-dark-3:hover, .force-dark-mode .button.shadow-dark:hover {box-shadow: 4px 4px 0 rgba(52, 55, 58, 0.6);}
.force-dark-mode .button.shadow-dark-3:active, .force-dark-mode .button.shadow-dark:active {box-shadow: 2px 2px 0 rgba(52, 55, 58, 0.6);}

/* Dark Mode */
@media (prefers-color-scheme: dark) {
    .button.shadow-1:hover {box-shadow: 2px 2px 0 rgba(52, 55, 58, 0.3);}
    .button.shadow-1:active {box-shadow: 0px 0px 0 rgba(52, 55, 58, 0.3);}
    
    .button.shadow-2:hover {box-shadow: 3px 3px 0 rgba(52, 55, 58, 0.3);}
    .button.shadow-2:active {box-shadow: 1px 1px 0 rgba(52, 55, 58, 0.3);}
    
    .button.shadow-3:hover, .button.shadow:hover {box-shadow: 4px 4px 0 rgba(52, 55, 58, 0.3);}
    .button.shadow-3:active, .button.shadow:active {box-shadow: 2px 2px 0 rgba(52, 55, 58, 0.3);}
    
    /* shadow dark */
    .button.shadow-dark-1:hover {box-shadow: 2px 2px 0 rgba(52, 55, 58, 0.6);}
    .button.shadow-dark-1:active {box-shadow: 0px 0px 0 rgba(52, 55, 58, 0.6);}
    
    .button.shadow-dark-2:hover {box-shadow: 3px 3px 0 rgba(52, 55, 58, 0.6);}
    .button.shadow-dark-2:active {box-shadow: 1px 1px 0 rgba(52, 55, 58, 0.6);}
    
    .button.shadow-dark-3:hover, .button.shadow-dark:hover {box-shadow: 4px 4px 0 rgba(52, 55, 58, 0.6);}
    .button.shadow-dark-3:active, .button.shadow-dark:active {box-shadow: 2px 2px 0 rgba(52, 55, 58, 0.6);}

    /* forced light mode */
    .force-light-mode .button.shadow-1:hover {box-shadow: 2px 2px 0 rgba(211, 219, 224, 0.3);}
    .force-light-mode .button.shadow-1:active {box-shadow: 0px 0px 0 rgba(211, 219, 224, 0.3);}

    .force-light-mode .button.shadow-2:hover {box-shadow: 3px 3px 0 rgba(211, 219, 224, 0.3);}
    .force-light-mode .button.shadow-2:active {box-shadow: 1px 1px 0 rgba(211, 219, 224, 0.3);}

    .force-light-mode .button.shadow-3:hover, .force-light-mode .button.shadow:hover {box-shadow: 4px 4px 0 rgba(211, 219, 224, 0.3);}
    .force-light-mode .button.shadow-3:active, .force-light-mode .button.shadow:active {box-shadow: 2px 2px 0 rgba(211, 219, 224, 0.3);}

    /* shadow dark */
    .force-light-mode .button.shadow-dark-1:hover {box-shadow: 2px 2px 0 rgba(211, 219, 224, 0.6);}
    .force-light-mode .button.shadow-dark-1:active {box-shadow: 0px 0px 0 rgba(211, 219, 224, 0.6);}

    .force-light-mode .button.shadow-dark-2:hover {box-shadow: 3px 3px 0 rgba(211, 219, 224, 0.6);}
    .force-light-mode .button.shadow-dark-2:active {box-shadow: 1px 1px 0 rgba(211, 219, 224, 0.6);}

    .force-light-mode .button.shadow-dark-3:hover, .force-light-mode .button.shadow-dark:hover {box-shadow: 4px 4px 0 rgba(211, 219, 224, 0.6);}
    .force-light-mode .button.shadow-dark-3:active, .force-light-mode .button.shadow-dark:active {box-shadow: 2px 2px 0 rgba(211, 219, 224, 0.6);}

}