.DocumentToolbar {
    position: absolute;
    top: 32px;
    left: 0;
    width: 100%;
    height: 38px;
    overflow: hidden;
}

.tab .DocumentToolbar {
    padding-left: 200px;
    z-index: 1;
    top: 0;
    width: 100%;

    transition: padding-left 0.3s ease-out;
}

.tab .DocumentToolbar.diceDrawerOpen {
    padding-left: 570px;
}

.DocumentToolbar .iconButton {
    stroke: none;
}

.DocumentToolbar .iconButton:hover {
    transform: scale(1.2)
}

.DocumentToolbar .image-upload-button:hover {
    transform: scale(1.2)
}

.DocumentToolbar .toggleEntry svg {
    transition: transform 0.1s ease-out;
}

.DocumentToolbar .toggleEntry:hover svg {
    transform: scale(1.2)
}


.ShareModal {
    position: relative;
}

.ShareModal-rolesPanel {
    position: absolute;
    top: 42px;
    left: -146px;

    display: none;
    z-index: 2;

    border-radius: 8px;
    border-top-right-radius: 0;
}

.ShareModal-rolesPanel.open {
    display: block;
}

.image-upload-button input {
    display: none;
}

@media only screen and (max-width: 750px) {
    .tab .DocumentToolbar {
        padding-left: 80px;
    }
}