.documentListItem {
    position: relative;
}

.documentListItem--wrapper {
    z-index: 1;
}

.documentListItem .minimize svg {
    stroke: #404d55;
}

.documentListItem--title {
    text-transform: capitalize;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.documentListItem .documentListItem--body {
    margin-top: -1rem;
    padding-top: 1rem;
}

.documentListItem--removeButton:hover .Icon svg line {
    stroke: #e17e7e;
}

.documentListItem .noteEditor > .ql-container {
    height: 100%;
    margin-top: 0;
}

.documentListItem .noteEditor .ql-editor {
    padding: 1rem;
}

.documentListItem  .documentListItem--openButton {
    width: 0;
    opacity: 0;
    stroke: #647682;
    transition: width 0.1s ease-out, opacity 0.1s ease-out;
}

.documentListItem--rightContainer:hover .documentListItem--openButton  {
    stroke: #6EADC6;
}


.documentListItem:hover .documentListItem--openButton {
    width: 2rem;
    opacity: 1;
}

.documentListItem--document {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 0 0 8px 8px;
}