.PictureViewer {
    height: 100%;
    width: 100%;
    top: 0px;
    position: relative;
    overflow: hidden;
}

.tab .PictureViewer {
    height: calc(100% - 38px);
    top: 18px;
}

.pictureViewer__img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    object-fit: contain;
    pointer-events: none;

    transition: object-position 0.3s ease-out;
}

.pictureViewer__img.cover {
    object-fit: cover;
}

.pictureViewer__img.contain {
    object-fit: contain;
}

.pictureViewer__img.top {
    object-position: top center;
}

.pictureViewer__img.left {
    object-position: left center;
}

.pictureViewer__img.right {
    object-position: right center;
}

.pictureViewer__img.bottom {
    object-position: bottom center;
}