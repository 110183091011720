.loading-dialog {
    width: 100%;
    height: 100%;
    color: #647682;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 12px;
}

.loading-dialog.full {
    height: 100vh;
}

.loading-dialog-spinner {
    width: 100%;
    height: 100%;
    max-width: 64px;
    max-height: 64px;
}

.loading-dialog.small .loading-dialog-spinner {
    max-width: 32px;
    max-height: 32px;
}

.loading-dialog.overlay {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 10000;
    border-radius: 0;
}

.loading-dialog.opque {background-color: #FBFBFB;}
.loading-dialog.overlay.opaque {background-color: #FBFBFB;}

.force-dark-mode .loading-dialog.overlay {background-color: rgba(0, 0, 0, 0.75);}
.force-dark-mode .loading-dialog.opaque {background-color: #21262c;}
/* Dark Mode */
@media (prefers-color-scheme: dark) {    
  .loading-dialog.overlay {background-color: rgba(0, 0, 0, 0.75);}
  .loading-dialog.opaque {background-color: #21262c;}

    /* Forced Light theme in dark mode */
    .force-light-mode .loading-dialog.overlay {background-color: rgba(255, 255, 255, 0.75);}
    .force-light-mode .loading-dialog.opaque {background-color: #FBFBFB;}
}