.diceTray {
    position: absolute;
    width: 480px;
    height: 320px;
    cursor: pointer;

    top: calc(50% - 128px);
    left: calc(50% - 240px);

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -3;

    border: 6px solid #FFEDD6;
    box-shadow: 1px 1px 0 #FCD7A8,
    2px 2px 0 #FCD7A8,
    3px 3px 0 #FCD7A8,
    4px 4px 0 #FCD7A8,
    5px 5px 0 #FCD7A8,
    1px 1px 0 #FCD7A8 inset,
    2px 2px 0 #FCD7A8 inset,
    3px 3px 0 #FCD7A8 inset,
    4px 4px 0 #FCD7A8 inset,
    5px 5px 0 #FCD7A8 inset,
    
    8px 8px 0 rgba(211, 219, 224, 0.3);

    transition: transform 0.1s ease-out, box-shadow 0.1s ease-out;
}

.diceTray.locked {
    cursor: default;
    transform: translate(3px, 3px);
    box-shadow: 1px 1px 0 #FCD7A8,
    2px 2px 0 #FCD7A8,
    3px 3px 0 #FCD7A8,
    4px 4px 0 #FCD7A8,
    5px 5px 0 #FCD7A8,
    1px 1px 0 #FCD7A8 inset,
    2px 2px 0 #FCD7A8 inset,
    3px 3px 0 #FCD7A8 inset,
    4px 4px 0 #FCD7A8 inset,
    5px 5px 0 #FCD7A8 inset,
    
    0px 0px 0 rgba(211, 219, 224, 0.3);
}

.diceTray div {
    pointer-events: none;
}

.diceTray.blue {background-color: #7FAABC;}
.diceTray.red {background-color: #E17E7E;}
.diceTray.yellow {background-color: #C1A175;}
.diceTray.green {background-color: #9BD095;}
.diceTray.stone {background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("../../assets//stone-background-dice-tray.png");}

.diceTray .Icon {
    width: 82px;
    height: 82px;
}

.diceTray.blue .Icon {fill: #5990A7;}
.diceTray.red .Icon {fill: #C46363;}
.diceTray.yellow .Icon {fill: #A2825A;}
.diceTray.green .Icon {fill: #65AF5D;}
.diceTray.stone .Icon {fill: #85a4b7;}

.dice-tray-lock-button {
    position: absolute;
    top: 16px;
    right: 16px;
}

.dice-tray-lock-button svg {
    stroke: none;
}

.dice-tray-lock-button.blue svg {fill: #5990A7;}
.dice-tray-lock-button.red svg {fill: #C46363;}
.dice-tray-lock-button.yellow svg {fill: #A2825A;}
.dice-tray-lock-button.green svg {fill: #65AF5D;}
.dice-tray-lock-button.stone svg {fill: #85a4b7;}

.dice-tray-lock-button.blue:hover {background: none; }
.dice-tray-lock-button.red:hover {background: none; }
.dice-tray-lock-button.yellow:hover {background: none; }
.dice-tray-lock-button.green:hover {background: none; }

.dice-tray-lock-button.blue:hover svg {fill: white; }
.dice-tray-lock-button.red:hover svg {fill: white; }
.dice-tray-lock-button.yellow:hover svg {fill: white; }
.dice-tray-lock-button.green:hover svg {fill: white; }
