.pings {
    width: 100%;
    height: 100%;
    pointer-events: none;
    position: absolute;
    z-index: 1000000;
}

.pings .ping {
    position: absolute;
    width: 128px;
    height: 128px;
    transform: translate(-66px, -63px) scale(1);
    border-radius: 100%;
    border: 6px solid currentColor;
    transform-origin: center;

    animation: grow-in-ring 1s cubic-bezier(.63, .31, .42, 1.24) forwards;
}

.pings .ping::after {
    position: absolute;
    content: '';
    top: 22px;
    left: 22px;
    width: 64px;
    height: 64px;
    border-radius: 100%;
    border: 4px solid currentColor;
    transform-origin: center;
    animation: grow-in-2 1s cubic-bezier(.63, .31, .42, 1.24);
}


.pings .ping::before {
    position: absolute;
    content: '';
    top: 40px;
    left: 40px;
    width: 32px;
    height: 32px;
    border-radius: 100%;
    border: 2px solid currentColor;
    animation: grow-in-2 1s cubic-bezier(.63, .31, .42, 1.24);
}

@keyframes grow-in-ring {
    0% {
        transform: translate(-66px, -63px) scale(0);
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: translate(-66px, -63px) scale(1);
        opacity: 0;
    }
}

@keyframes grow-in-2 {
    0% {
        transform: scale(0);
        opacity: 1;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}