
.ChestButton, .TomeButton {
    position: absolute;
    top: 46px;
    left: 46px;
    background: none;
    border: none;
    box-shadow: none;
    padding: 0;
    width: 86px;
    height: 86px;
    z-index: 1;
    opacity: 1;
    pointer-events: all;
    transform-origin: -20% -20%;
}

.ChestButton .Icon {width: 38px; transition: width 0.3s ease-out, height 0.3s ease-out;}

.ChestButton:hover, .ChestButton:active, .TomeButton:hover, .TomeButton:active {
    transform-origin: 50% 50%;
    animation: shake 0.3s ease-out;
    animation-fill-mode: forwards;
    background-color: transparent;
    box-shadow: none;
}

.chest.open .ChestButton, .chest.open .TomeButton {
    top: 20px;
    left: 8px;
    width: 48px;
    height: 48px;
}

.chest.open .ChestButton .Icon { width: 38px; transition: width 0.3s ease-out, height 0.3s ease-out;}

.ChestButton {
    transition: left 0.5s ease-out, top 0.3s ease-out, width 0.3s ease-out, height 0.3s ease-out, transform 0.3s ease-out, opacity 0s linear;
}

.TomeButton {
    transform: rotate(180deg);
    opacity: 0;
    pointer-events: none;
    transition: left 0.5s ease-out, top 0.3s ease-out, width 0.3s ease-out, height 0.3s ease-out, transform 0.3s ease-out, opacity 0s linear 0.3s;
}

.TomeButton .Icon {
    width: 38px;
    height: 38px;
    transition: width 0.3s ease-out, height 0.3s ease-out;
}

.chest.open .TomeButton .Icon {
    width: 38px;
    height: 38px;
}

.chest.active-campaign .ChestButton {
    opacity: 0;
    pointer-events: none;
    transform: rotate(180deg);
    transition: left 0.5s ease-out, top 0.3s ease-out, width 0.3s ease-out, height 0.3s ease-out, transform 0.3s ease-out, opacity 0s linear 0.3s;
}

.chest.active-campaign .TomeButton {
    opacity: 1;
    pointer-events: all;
    transform: rotate(360deg);
    transition: left 0.5s ease-out, top 0.3s ease-out, width 0.3s ease-out, height 0.3s ease-out, transform 0.3s ease-out, opacity 0s linear;
}