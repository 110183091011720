.error {
    position: fixed;
    top: -100px;
    padding: 0 1rem;
    left: 0;
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    
    pointer-events: none;
    z-index: 10000000;

    transition: all 0.3s ease-out;
}

.error-inner {
    width: auto;
    border-radius: 12px;
    padding: 0.5rem 1rem;
    margin: 0 auto;
    background-color: #F86E6E;
    border: 1px solid rgb(204, 85, 85);
    color: white;
    font-weight: bold;
    transition: all 0.3s ease-out;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    cursor: pointer;

    pointer-events: all;
}

.error-show {
    top: 24px;
}

.error-show .error-inner{
    box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.15);
}

.error.pending .error-inner {
    background-color: #FFF6EB;
    border: 1px solid #FFDCAD;
    color: #647682;
}

.error.success .error-inner {
    background-color: #9bd095;
    border: 1px solid #87C77F;
}