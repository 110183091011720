.map-wrapper.pan {
    cursor: move;
}

.map-wrapper.measure,
.map-wrapper.ping,
.map-wrapper.draw,
.map-wrapper.cut-fog,
.map-wrapper.fog-fill {
    cursor: crosshair;
}

.map-clear-paint-button {
    position: absolute;
    top: 76px;
    left: 0;
}

.map-wrapper {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
}