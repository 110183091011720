.soundControls {
    position: relative;
    right: 0;
    bottom: 0;
    width: 200px;
    border: 2px solid;
    border-radius: 24px;
    height: 32px;
    overflow: hidden;
    z-index: 10000000000;
    pointer-events: all;

    transition: all 0.3s ease-out;
}

.soundControls.open,
.soundControls.muted {
    transform: translateY(0);
    overflow: none;
    height: 320px;
    width: 350px;
    padding-top: 0.25rem;
}

.soundControlsTitleContainer {
    padding: 0 1rem;
    padding-top: 1px;
    transition: padding 0.3s ease-out;
}

.soundControls.open .soundControlsTitleContainer {
    padding-top: 0.5rem;
}

.soundEntry {
    min-height: 94px;
}

.soundEntry.minified {
    min-height: 26px;
    margin: 4px 0;
    font-size: 10px;
}

.soundEntry.minified svg {
    min-width: 12px;
    min-height: 12px;
    width: 12px;
    height: 12px;
}

.soundEntry.minified .loading-dialog {
    min-width: 16px;
    min-height: 12px;
    width: 12px;
    height: 12px;
    padding: 0;
}

.soundControlsList {
    position: relative;
    opacity: 0;
    overflow: auto;
    max-height: calc(100% - 56px);
    transition: opacity 0.3s ease-out;
}

.soundControls.open .soundControlsList {
    opacity: 1;
}

.soundControlsListFade {
    pointer-events: none;
    opacity: 0;
    position: absolute;
    background: linear-gradient(to top, rgba(251, 251, 251, 1), rgba(251, 251, 251, 0));
    height: 16px;
    width: calc(100% - 16px);
    transform: translateY(-16px);
    transition: opacity 0.3s ease-out;
}

.soundControls.open .soundControlsListFade {
    opacity: 1;
}

.soundEntryTime {
    opacity: 1;
    transition: opacity 0.3s ease-out;
}

.soundEntryTime.hide {
    opacity: 0;
}


/* Forced Dark mode */
.force-dark-mode .soundControlsListFade {
    background: linear-gradient(to top, rgba(33, 38, 44, 1), rgba(33, 38, 44, 0));
}

/* Dark Mode */
@media (prefers-color-scheme: dark) {
    .soundControlsListFade {
        background: linear-gradient(to top, rgba(33, 38, 44, 1), rgba(33, 38, 44, 0));
    }

    /* Forced Light theme in dark mode */
    .force-light-mode .soundControlsListFade {
        background: linear-gradient(to top, rgba(251, 251, 251, 1), rgba(251, 251, 251, 0));
    }
}