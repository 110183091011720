.cancelDiceButton {
    position: absolute;
    bottom: 64px;
    right: 50%;

    height: 64px;
    width: 64px;
    transform: translate(50%, 0px);
    opacity: 0;
    pointer-events: none;
    z-index: 10000;
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.cancelDiceButton.active {
    opacity: 1;
    transform: translate(50%, 0px);
    pointer-events: all;
}

.cancelDiceButton:hover {
    transform: translateX(50%, 0px);
}

.cancelDiceButton svg {
    fill: white;
    pointer-events: none;
}