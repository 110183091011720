
.chest-user-icon-button {
    color: #647682;
    display: flex;
    flex: 1 1;
    align-items: center;
    justify-content: flex-end;
    background: none;
    box-shadow: none;
    outline: none;
    border: none;
    border-radius: 0%;
    padding: 0;
    padding-right: 1rem;
    transition: color 0.1s ease-out;
}

.chest-user-icon-button.button-container {
    padding-right: 0;
}

.chest-user-icon-button:active, .chest-user-icon-button:focus {
    background: none;
    color: #647682;
    transform: none;
}

.chest-user-icon-button:hover {
    background: none;
    color: #adadad;
    transform: none;
    box-shadow: none;
}

.chest-user-icon-button .user-icon-circle {
    margin-left: 1rem;
}

.user-icon-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding-left: 1px;
    border-radius: 6px;
    border: 2px solid #ECEFF3;
    font-size: 1rem;
    font-weight: bold;
    transition: color 0.3s ease-out;
    color: #647682;
    text-transform: uppercase;

    font-family: 'Poppins', sans-serif;
    font-weight: bold;
}

.user-icon-circle.red {background-color: #EFBDBD;}
.user-icon-circle.green {background-color: #C9E6C6;}
.user-icon-circle.blue {background-color: #B4E1E9;}
.user-icon-circle.yellow {background-color: #FFEDD6;}