/* ------------------------------ Flex ------------------------------ */

.row {
    display: flex;
    flex-direction: row;
}

.row-reverse {
    display: flex;
    flex-direction: row-reverse;
}

@media only screen and (max-width: 769px) {

    .col-mobile,
    .row.col-mobile {
        display: flex;
        flex-direction: column;
    }

    .col-reversed-mobile,
    .row.col-reversed-mobile {
        display: flex;
        flex-direction: column-reverse;
    }
}

.col {
    display: flex;
    flex-direction: column;
}

.col-reversed {
    display: flex;
    flex-direction: column-reverse;
}

.centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.centered-vertically {
    display: flex;
    align-items: center;
}

.centered-horizontally {
    display: flex;
    justify-content: center;
}

.flex-start {
    display: flex;
    justify-content: flex-start;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.align-end {
    display: flex;
    align-items: flex-end;
}

.space-evenly {
    display: flex;
    justify-content: space-evenly;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.space-around {
    display: flex;
    justify-content: space-around;
}

/* ------------------------------ Paddings ------------------------------ */


.padding-0,
.padding-none {
    padding: 0;
}

.padding-quarter {
    padding: 0.25rem;
}

.padding-half {
    padding: 0.5rem;
}

.padding-dot75 {
    padding: 0.75rem;
}

.padding-1 {
    padding: 1rem;
}

.padding-2 {
    padding: 2rem;
}

.padding-3 {
    padding: 3rem;
}

.padding-horizontal-quarter {
    padding: 0 0.25rem;
}

.padding-horizontal-half {
    padding: 0 0.5rem;
}

.padding-horizontal-1 {
    padding: 0 1rem;
}

.padding-horizontal-2 {
    padding: 0 2rem;
}

.padding-horizontal-3 {
    padding: 0 3rem;
}

.padding-vertical-half {
    padding: 0.5rem 0;
}

.padding-vertical-1 {
    padding: 1rem 0;
}

.padding-vertical-2 {
    padding: 2rem 0;
}

.padding-vertical-3 {
    padding: 3rem 0;
}

.padding-top-0 {
    padding-top: 0 !important;
}

.padding-top-half {
    padding-top: 0.5rem;
}

.padding-top-1 {
    padding-top: 1rem;
}

.padding-top-2 {
    padding-top: 2rem;
}

.padding-top-3 {
    padding-top: 3rem;
}

.padding-top-4 {
    padding-top: 4rem;
}

.padding-bottom-0 {
    padding-bottom: 0;
}

.padding-bottom-quarter {
    padding-bottom: 0.25rem;
}

.padding-bottom-half {
    padding-bottom: 0.5rem;
}

.padding-bottom-75 {
    padding-bottom: 0.75rem;
}

.padding-bottom-1 {
    padding-bottom: 1rem;
}

.padding-bottom-2 {
    padding-bottom: 2rem;
}

.padding-bottom-3 {
    padding-bottom: 3rem;
}

.padding-bottom-4 {
    padding-bottom: 4rem;
}

.padding-bottom-5 {
    padding-bottom: 5rem;
}

.padding-left-half {
    padding-left: 0.5rem;
}

.padding-left-0 {
    padding-left: 0;
}

.padding-left-quarter {
    padding-left: 0.25rem;
}

.padding-left-half {
    padding-left: 0.5rem;
}

.padding-left-1 {
    padding-left: 1rem;
}

.padding-left-2 {
    padding-left: 2rem;
}

.padding-left-3 {
    padding-left: 3rem;
}

.padding-right-0 {
    padding-right: 0;
}

.padding-right-dot75 {
    padding-right: 0.75rem;
}

.padding-right-quarter {
    padding-right: 0.25rem;
}

.padding-right-half {
    padding-right: 0.5rem;
}

.padding-right-1 {
    padding-right: 1rem;
}

.padding-right-2 {
    padding-right: 2rem;
}

.padding-right-3 {
    padding-right: 3rem;
}


/* ------------------------------ Margins ------------------------------ */



.margin-0,
.margin-none {
    margin: 0;
}

.margin-auto {
    margin: 0 auto;
}

.margin-quarter {
    margin: 0.25rem;
}

.margin-half {
    margin: 0.5rem;
}

.margin-dot75 {
    margin: 0.75rem;
}

.margin-1 {
    margin: 1rem;
}

.margin-2 {
    margin: 2rem;
}

.margin-3 {
    margin: 3rem;
}

.margin-4 {
    margin: 4rem;
}

.margin-horizontal-quarter {
    margin: 0 0.25rem;
}

.margin-horizontal-half {
    margin: 0 0.5rem;
}

.margin-horizontal-1 {
    margin: 0 1rem;
}

.margin-horizontal-2 {
    margin: 0 2rem;
}

.margin-horizontal-3 {
    margin: 0 3rem;
}

.margin-vertical-1 {
    margin: 1rem 0;
}

.margin-vertical-2 {
    margin: 2rem 0;
}

.margin-vertical-3 {
    margin: 3rem 0;
}

.margin-top-0 {
    margin-top: 0;
}

.margin-top-quarter {
    margin-top: 0.25rem;
}

.margin-top-half {
    margin-top: 0.5rem;
}

.margin-top-1 {
    margin-top: 1rem;
}

.margin-top-2 {
    margin-top: 2rem;
}

.margin-top-3 {
    margin-top: 3rem;
}

.margin-top-4 {
    margin-top: 4rem;
}

.margin-bottom-0 {
    margin-bottom: 0;
}

.margin-bottom-quarter {
    margin-bottom: 0.25rem;
}

.margin-bottom-half {
    margin-bottom: 0.5rem;
}

.margin-bottom-1 {
    margin-bottom: 1rem;
}

.margin-bottom-2 {
    margin-bottom: 2rem;
}

.margin-bottom-3 {
    margin-bottom: 3rem;
}

.margin-bottom-4 {
    margin-bottom: 4rem;
}

.margin-left-quarter {
    margin-left: 0.25rem;
}

.margin-left-0 {
    margin-left: 0;
}

.margin-left-half {
    margin-left: 0.5rem;
}

.margin-left-1 {
    margin-left: 1rem;
}

.margin-left-2 {
    margin-left: 2rem;
}

.margin-left-3 {
    margin-left: 3rem;
}

.margin-right-0 {
    margin-right: 0;
}

.margin-right-quarter {
    margin-right: 0.25rem;
}

.margin-right-half {
    margin-right: 0.5rem;
}

.margin-right-1 {
    margin-right: 1rem;
}

.margin-right-2 {
    margin-right: 2rem;
}

.margin-right-3 {
    margin-right: 3rem;
}

.top-0 {
    top: 0;
}

.bottom-0 {
    bottom: 0;
}

/* ------------------------------ Extras ------------------------------ */
.section {
    /* maybe this needs to be a component */
    display: flex;
    width: 100%;
}

.push-off-window {
    position: absolute;
    bottom: 2rem;
}

.position-absolute {
    position: absolute;
}

.position-relative {
    position: relative;
}

.center-full-width {
    width: calc(100% - 4rem);
    text-align: center;
}

.wrap {
    flex-wrap: wrap;
}

.overflow-auto {
    overflow: auto;
}

.flex-grow-1 {
    flex-grow: 1;
}