.bc-ice {border-color: #f2f9fd;}
.bc-light-blue {border-color: #b4e1e9;}
.bc-blue {border-color: #6EADC6;}
.bc-dark-blue {border-color: #7faabc;}
.bc-midnight-blue {border-color: #404d55;}
.bc-red {border-color: #e17e7e;}
.bc-light-red {border-color: #EC9898;}
.bc-dark-red {border-color: #DF5353;}
.bc-green {border-color: #9bd095;}
.bc-light-green {border-color: #BCE0B8;}
.bc-dark-green {border-color: #87C77F;}
.bc-yellow {border-color: #FFDCAD}
.bc-light-yellow {border-color: #ffedd6}
.bc-dark-yellow {border-color: #f5c88e}
.bc-cyan {border-color: #b4e1e9;}
.bc-light-cyan {border-color: #f2f9fd;}
.bc-dark-cyan {border-color: #90D3DF;}
.bc-purple {border-color: #B18CD9;}
.bc-pink{border-color: #FFC8E5;}
.bc-orange{border-color: #FFB864;}

.bc-white {border-color: #FBFBFB;}
.bc-shade-1 {border-color: #f5f9fa;}
.bc-shade-2 {border-color: #eceff3;}
.bc-shade-3 {border-color: #dee1e8;}
.bc-shade-4 {border-color: #ccd5db;}
.bc-shade-5 {border-color: #abb6bf;}
.bc-shade-6, .bc-main {border-color: #647682;}
.bc-shade-7 {border-color: #465058;}
.bc-shade-8 {border-color: #333c43;}
.bc-shade-9 {border-color: #21262c;}


/* Forced Dark Mode */
.force-dark-mode .bc-white {border-color: #21262c;}
.force-dark-mode .bc-shade-1 {border-color: #333c43;}
.force-dark-mode .bc-shade-2 {border-color: #465058;}
.force-dark-mode .bc-shade-3, .force-dark-mode .bc-main {border-color: #647682;}
.force-dark-mode .bc-shade-4 {border-color: #abb6bf;}
.force-dark-mode .bc-shade-5 {border-color: #ccd5db;}
.force-dark-mode .bc-shade-6 {border-color: #dee1e8;}
.force-dark-mode .bc-shade-7 {border-color: #eceff3;}
.force-dark-mode .bc-shade-8 {border-color: #f5f9fa;}
.force-dark-mode .bc-shade-9 {border-color: #FBFBFB;}

/* Dark Mode */
@media (prefers-color-scheme: dark) {    
    .bc-white {border-color: #21262c;}
    .bc-shade-1 {border-color: #333c43;}
    .bc-shade-2 {border-color: #465058;}
    .bc-shade-3, .bc-main {border-color: #647682;}
    .bc-shade-4 {border-color: #abb6bf;}
    .bc-shade-5 {border-color: #ccd5db;}
    .bc-shade-6 {border-color: #dee1e8;}
    .bc-shade-7 {border-color: #eceff3;}
    .bc-shade-8 {border-color: #f5f9fa;}
    .bc-shade-9 {border-color: #FBFBFB;}

    /* Forced Light theme in dark mode */
    .force-light-mode .bc-white {border-color: #FBFBFB;}
    .force-light-mode .bc-shade-1 {border-color: #f5f9fa;}
    .force-light-mode .bc-shade-2 {border-color: #eceff3;}
    .force-light-mode .bc-shade-3, .force-light-mode .bc-main {border-color: #dee1e8;}
    .force-light-mode .bc-shade-4 {border-color: #ccd5db;}
    .force-light-mode .bc-shade-5 {border-color: #abb6bf;}
    .force-light-mode .bc-shade-6 {border-color: #647682;}
    .force-light-mode .bc-shade-7 {border-color: #465058;}
    .force-light-mode .bc-shade-8 {border-color: #333c43;}
    .force-light-mode .bc-shade-9 {border-color: #21262c;}
}