.list {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    list-style: none;
    border-radius: 12px;
    padding: 1rem;
    overflow-y: auto;
}

.list-empty-message {
    color: #647682;
    width: 100%;
    height: 100%;
    margin: 0;
    text-align: center;
    font-size: 0.75rem;

    display: flex;
    justify-content: center;
    align-items: center;
}

.list-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 0.5rem;
    border-radius: 8px;
    margin-bottom: 0.5rem;
    cursor: pointer;
    transition: background-color 0.1s ease-out;
    list-style: none;
}

.list-item.disabled {
    opacity: 0.75;
    background-color: none;
}

.list-item.disabled:hover {
    background-color: none;
}

.list-item.selected {
    background-color: #ECEFF3;
}

.list-item.selected:hover {
    background-color: #ccd5db;
}

.list-item-icon {
    width: 32px;
    height: 32px;
}

.list-item-remove-button .Icon{
    max-width: 16px;
    stroke: #fbfbfb;
}

.list-item:hover {background-color: #ECEFF3;}

.force-dark-mode .list-item:hover {background-color: #465058;}
.force-dark-mode .list-item.selected {background-color: #465058;}
.force-dark-mode .list-item.selected:hover {background-color: #647682;}


@media (prefers-color-scheme: dark) {    
    .list-item:hover {background-color: #465058;}
    .list-item.selected {background-color: #465058;}
    .list-item.selected:hover {background-color: #647682;}

    .force-light-mode .list-item:hover {background-color: #ECEFF3;}
    .force-light-mode .list-item.selected {background-color: #ECEFF3;}
    .force-light-mode .list-item.selected:hover {background-color: #ccd5db;}
}

.list-item:hover .list-item-remove-button .Icon {
    stroke: #CCD5DB;
}

.hidden-list {
    align-self: flex-start;
    justify-self: flex-end;
    width: 100%;
}

.hidden-list .expand-list {
    width: 12px;
    height: 12px;
}

.hidden-list.open {
    flex: 1 1;
}

.hidden-list.open .hidden-list-toggle svg {
    transform: rotate(90deg);
}

.hidden-list .list {
    margin: 0;
}

.hidden-list-toggle {
    display: flex;
    justify-content: flex-start;
    background: none;
    border: none;
    box-shadow: none;
    transform: none;
    color: #647682;
}

.hidden-list-toggle:hover, .hidden-list-toggle:active {
    background: none;
    border: none;
    box-shadow: none;
    transform: none;
    color: #465058;
}

.hidden-list-toggle:hover svg, .hidden-list-toggle:active svg {
    stroke: #465058;
}

.hidden-list-toggle svg {
    stroke: #647682;
    max-width: 16px;
    max-height: 16px;
    transition: stroke 0.3s ease-out;
}

.hidden-list-body {
    display: flex;
    height: calc(100% - 1rem);
}

.list-item .button-container {
    padding: 0;
}

.list-item .circleButton {
    max-height: 24px;
    max-width: 24px;
    padding: 0;
    border-width: 1px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.list-item .circleButton svg {
    stroke: #647682;
    stroke-width: 3px;
    width: 12px;
    height: 10px;
    transition: stroke 0.3s ease-out;
}

.list-item .circleButton.red svg {
    stroke: #a83e3e;
}