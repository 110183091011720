
.Slider[type=range] {
    -webkit-appearance: none;
    border-radius: 12px;
  }

  .Slider[type=range]:focus {
    outline: none;
  }

  .Slider[type=range]::-webkit-slider-runnable-track {
    height: 5px;
    cursor: pointer;
    background: none;
    border-radius: 12px;
  }

  .square.Slider[type=range]::-webkit-slider-runnable-track, .square.Slider[type=range] {
    border-radius: 0;
  }

  .Slider[type=range]::-webkit-slider-thumb {
    border: 1px solid #CCD5DB;
    height: 16px;
    width: 16px;
    border-radius: 100%;
    background: #ffffff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5px;
  }

  .hideKnob.Slider[type=range]::-webkit-slider-thumb {
    opacity: 0;
  }

  .Slider[type=range]:focus::-webkit-slider-runnable-track {
    background: none;
  }

  .Slider[type=range]::-moz-range-track {
    height: 5px;
    cursor: pointer;
    background: none;
    border-radius: 12px;
  }

  .Slider[type=range]::-moz-range-thumb {
    border: 1px solid #CCD5DB;
    height: 16px;
    width: 16px;
    border-radius: 100%;
    background: #ffffff;
    cursor: pointer;
  }

  .Slider[type=range]::-ms-track {
    height: 5px;
    cursor: pointer;
    background: none;
    border-radius: 12px;
  }

  .Slider[type=range]::-ms-fill-lower {
    background: #2a6495;
    border: 1px solid #CCD5DB;
    border-radius: 12px;
  }

  .Slider[type=range]::-ms-fill-upper {
    background: #3071a9;
    border: 1px solid #CCD5DB;
    border-radius: 12px;
  }

  .Slider[type=range]::-ms-thumb {
    border: 1px solid #CCD5DB;
    border-radius: 100%;

    background: #ffffff;
    cursor: pointer;
  }

  .Slider[type=range]:focus::-ms-fill-lower {
    background: #3071a9;
  }

  .Slider[type=range]:focus::-ms-fill-upper {
    background: #367ebd;
  }