.relative {
    position: relative;
}

.absolute-fill {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 32px);
    opacity: 0.9;
    pointer-events: none;

    display: flex;
    justify-content: center;
    align-items: center;
}

.SoundboardIcon {
    position: relative;
    width: 20%;
    margin: 2.5%;
    height: auto;
    padding: 0;
    height: calc(100% - 16px);
    border-radius: 12px;
    overflow: hidden;
}

.SoundboardIcon p {
    padding-left: 4px;
    padding-right: 4px;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 1rem;
    font-size: 0.6rem;
    width: 100%;
    height: 16px;
    text-transform: capitalize;
}

.SoundboardIcon svg {
    padding-top: 8px;
    width: 38px;
    height: 38px;
}


.soundEntry.playing {
    background-image:
        repeating-linear-gradient(-45deg,
            transparent,
            transparent 1rem,
            #f5f9fa 1rem,
            #f5f9fa 2rem);
    background-size: 200% 200%;

    animation: barberpole 20s linear infinite;
}

@keyframes barberpole {
    100% {
        background-position: 100% 100%;
    }
}

.soundEntry svg {
    stroke: none;
}

.soundEntryWrapper {
    transform: translate(18px, 0.5rem);
}

.soundEntryCloseButton {
    pointer-events: none;
}

.soundEntry:hover .soundEntryCloseButton {
    pointer-events: all;
}

.soundEntryCloseButton svg {
    opacity: 0;
    transition: opacity 0.1s ease-out;
}

.soundEntry:hover .soundEntryCloseButton svg {
    opacity: 1;
}

.soundEntryTime {
    transform: translate(8px, 16px);
}

.soundboardCategoryLabel {
    position: absolute;
    top: 4px;
    left: 4px;
    text-align: left;
}

.soundSelectionIcon {
    height: calc(100% - 32px);
}

/* Forced Dark mode */

.force-dark-mode .soundEntry.playing {
    background-image:
        repeating-linear-gradient(-45deg,
            transparent,
            transparent 1rem,
            #333c43 1rem,
            #333c43 2rem);

    background-size: 200% 200%;
    animation: barberpole 20s linear infinite;
}

/* Dark Mode */
@media (prefers-color-scheme: dark) {
    .soundEntry.playing {
        background-image:
            repeating-linear-gradient(-45deg,
                transparent,
                transparent 1rem,
                #333c43 1rem,
                #333c43 2rem);

        background-size: 200% 200%;
        animation: barberpole 20s linear infinite;
    }

    /* Forced Light theme in dark mode */
    .force-light-mode .soundEntry.playing {
        background-image:
            repeating-linear-gradient(-45deg,
                transparent,
                transparent 1rem,
                #f5f9fa 1rem,
                #f5f9fa 2rem);
        background-size: 200% 200%;

        animation: barberpole 20s linear infinite;
    }
}