.card {
    padding: 0.5rem;
    border: 1px solid #CCD5DB;
    border-radius: 12px;
}

.card.emphasis-1{
    box-shadow: 3px 3px 0 rgba(100, 118,130, 0.15);
}

.card.emphasis-2{
    box-shadow: 6px 6px 0 rgba(100, 118,130, 0.15);
}

.card.padding-1 {
    padding: 1rem;
}

.card.yellow {
    border-color: #D8B588;
    background-color: #FFEDD6;
    color: #647682;
}

.card.green {
    border-color:#79BF71;
    background-color: #9BD095;
    color: white;
}

.card.red {
    border-color: #cf5757;
    background-color: #E17E7E;
    color: white;
}

.force-dark-mode .card.red {background-color: #E17E7E;}
.force-dark-mode .card.yellow {background-color: #FFEDD6;}
.force-dark-mode .card.green {background-color: #9BD095;}
/* Dark Mode */
@media (prefers-color-scheme: dark) {    
    .force-dark-mode .card.red {background-color: #E17E7E;}
    .force-dark-mode .card.yellow {background-color: #FFEDD6;}
    .force-dark-mode .card.green {background-color: #9BD095;}

    /* Forced Light theme in dark mode */
    .force-light-mode .card.red {background-color: #E17E7E;}
    .force-light-mode .card.yellow {background-color: #FFEDD6;}
    .force-light-mode .card.green {background-color: #9BD095;}
}

.card.border-thick {
    border-width: 3px;
}