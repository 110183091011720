.modal-mid-close {
    position: absolute;
    border-radius: 0 128px 128px 0;
    top: calc(50% - 42px);
    right: -20px;
    left: auto;
    height: 52px;
    width: 28px;
    border: 3px solid #ACDCE5;
    z-index: -20;

    transform: scaleX(0) translateY(-52px);
    animation: pop-in 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6) forwards;
    animation-delay: 0.6s;
}

@keyframes pop-in {
    from {transform: scale(0) translateY(-52px);}
    to {transform: scale(1) translateY(-52px);}
}

.modal-mid-close-foreground {
    position: absolute;
    top: calc(50% - 46px);
    right: 0px;
    left: auto;
    height: 58px;
    width: 28px;
    border-radius: 0;
    z-index: -10;

    border: none;
    outline: none;
    transform: scaleX(0);
    animation: pop-in-foreground 0.3s cubic-bezier(0.68, -0.6, 0.32, 1.6) forwards;
    animation-delay: 0.6s;
}

@keyframes pop-in-foreground {
    from {
        transform: scaleY(0);
    }

    to {
        transform: scaleY(1);
    }
}

.modal-mid-close.right {
    border-radius: 128px 0 0 128px;
    left: -20px;
    right: auto;
}

.modal-mid-close-foreground.right {
    left: 0px;
    right: auto;
}

.modal-mid-close-foreground:hover svg {
    transform: translateX(6px) scale(1.1);
}

.modal-mid-close-foreground.right:hover svg {
    transform: rotate(180deg) translateX(36px) scale(1.1);
}

.modal-mid-close-foreground svg {
    margin: 1rem;
    transition: transform 0.1s ease-out;
    transform: translateX(6px);
}

.modal-mid-close-foreground.right svg {
    transition: transform 0.1s ease-out;
    transform: rotate(180deg) translateX(36px);
}