.window {
    position: absolute;
    border: 3px solid #ABB6BF;
    border-radius: 12px;
    box-shadow: 6px 6px 0 rgba(100, 118, 130, 0.15);
    user-select: none;

    transition: box-shadow 0.3s ease-out,
        border 0.3s ease-out, background-color 0.8s ease-out,
        transform 0.3s ease-out,
        opacity 0.3s ease-out;
}

.window-title-input,
.hiddenTextInput {
    background: none;
    border: none;
    padding: 0.25rem 0.5rem;
    margin-right: 0.5rem;
    border-radius: 8px;
}

.window-title-input::placeholder,
.hiddenTextInput {
    color: #abb6bf;
}

.window-title-input:hover,
.window-title-input:focus,
.window-title-input:active,
.hiddenTextInput:hover,
.hiddenTextInput:focus,
.hiddenTextInput:active {
    outline: none;
    text-decoration: underline;
}

.hiddenTextInput:hover,
.hiddenTextInput:focus,
.hiddenTextInput:active {
    background-color: none;
}

.tabCloseButton {
    display: block;
    position: absolute;
    top: 0;
    right: 16px;
}

@media only screen and (max-width: 769px) {
    .window {
        width: 100% !important;
        height: 100% !important;
        top: 0 !important;
        left: 0 !important;
        z-index: 1000000000 !important;
        border-radius: 0;
    }

    .topbar {
        border-radius: 0 !important;
    }
}

.window.red .topbar {
    background: #e17e7e;
}

.window.green .topbar {
    background: #C9E6C6;
}

.window.yellow .topbar {
    background: #FFF6EB;
}

.window.blue .topbar {
    background: #f2f9fd;
}

.window.white .windowNav .button-container {
    display: block;
    margin-top: 0.5rem;
}

.window.full-green {
    background-color: #9BD095;
    border-color: #5BA353;
}

.window.selected {
    box-shadow: 7px 7px 0 rgba(100, 118, 130, 0.15);
}

.window.red {
    border-color: #e17e7e;
}

.window.selected.red {
    border-color: #ce5c5c;
}

.window.green {
    border-color: #C9E6C6;
}

.window.selected.green {
    border-color: #abe4a5;
}

.window.yellow {
    border-color: #ffedd6;
}

.window.selected.yellow {
    border-color: #f7d6ac;
}

.window.blue {
    border-color: #B4E1E9;
}

.window.selected.blue {
    border-color: #8dceda;
}

.window .windowNav svg {
    fill: #647682;
    stroke: none;
    transition: all 0.3s ease-out;
}

.topbar {
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 12px;
    padding-right: 8px;
    border-radius: 8px 8px 0 0;
    cursor: grabbing;
    user-select: none;
    transition: all 0.3s ease-out;
    overflow: hidden;
}

.windowNav .button-container {
    display: inline;
}

.windowNav .iconButton {
    /* width: 14px; */
    /* padding: 8px; */
    margin: 0;
    box-sizing: content-box;
}

.windowNav .iconButton:hover {
    background: none;
    transform: scale(1.2);
    box-shadow: none;
}

.windowInner {
    /* position: absolute; */
    /* top: 32px; */
    width: 100%;
    height: calc(100% - 32px);
    overflow: auto;
    border-radius: 0 0 8px 8px;
}

.scale.scale-bottom-right {
    bottom: -4px;
    right: -4px;
    cursor: nwse-resize;
    width: 16px;
    height: 16px;
}

.scale.scale-top-left {
    top: -4px;
    left: -4px;
    cursor: nwse-resize;
    width: 16px;
    height: 16px;
}

.scale.scale-top-right {
    top: -4px;
    right: -4px;
    cursor: nesw-resize;
    width: 16px;
    height: 16px;
}

.scale.scale-bottom-left {
    bottom: -4px;
    left: -4px;
    cursor: nesw-resize;
    width: 16px;
    height: 16px;
}

.scale.scale-left {
    left: -8px;
    top: 0;
    height: 100%;
    z-index: 10000000;
    cursor: ew-resize;
}

.scale.scale-right {
    right: -8px;
    top: 0;
    height: 100%;
    z-index: 10000000;
    cursor: ew-resize;
}

.scale.scale-top {
    left: 0;
    top: -8px;
    width: 100%;
    z-index: 10000000;
    cursor: ns-resize;
}

.scale.scale-bottom {
    left: 0;
    bottom: -8px;
    width: 100%;
    z-index: 10000000;
    cursor: ns-resize;
}

.scale {
    position: absolute;
    width: 8px;
    height: 8px;
    z-index: 100000000;
    transition: transform 0.1s ease-out;
}

.scale:hover {
    transform: scale(1.1);
}

.scale svg {
    stroke: #647682;
}


.window-section-right {
    display: flex;
    justify-content: flex-end;

    padding: 0;
}

.window.minimized {
    opacity: 0;
    transform: scale(0);
}